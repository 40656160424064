import { Input, Upload, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'
import { TEXTS } from './utils'
import React from 'react'

const CardContentForm = ({ category, generateStateMappers }) => {
  return (
    <div className="card-content">
      <Upload
        label={getMessage('app.card.content')}
        placeholder={getMessage('app.card.image.placeholder')}
        {...generateStateMappers({
          stateKeys: ['image'],
          loseEmphasisOnFill: true,
        })}
        required
      />
      {TEXTS[category].map((_, index) => (
        <Input
          key={index}
          type="text"
          placeholder={getMessage(`app.card.text.${index}`)}
          {...generateStateMappers({
            stateKeys: ['texts', `text${index + 1}`],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
          required
        />
      ))}
    </div>
  )
}

export default CardContentForm
