import React, { Fragment } from 'react'
import { Input } from '../../Form'
import { getMessage } from '../../../lib/translator'
import moment from 'moment'
import AddReward from './AddReward'

const TaskReward = ({ _this, state, task, method, taskIndex }) => {
  const { keys } = state
  return (
    <Fragment>
      <div className="row">
        <div className="col-12 field-label">
          <strong>
            {task.userAction === 'COMPLETE_CHALLENGE'
              ? getMessage('omnichallenge.task.reward')
              : getMessage('omnichallenge.task.reward.optional')}
          </strong>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mt pr">
          <Input
            label={getMessage('omnichallenge.task.rewardLimit')}
            className="reward-limit mobile-768"
            type="number"
            step="1"
            min={1}
            max={1000000}
            {..._this.generateStateMappers({
              stateKeys: keys[2],
              loseEmphasisOnFill: true,
            })}
            readOnly={
              method === 'edit' && moment().isAfter(_this.state.values.startAt)
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AddReward
            _this={_this}
            method={method}
            task={task}
            keys={keys}
            taskIndex={taskIndex}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default TaskReward
