import React from 'react'
import { getMessage } from 'lib/translator'
import { Input, Textarea } from 'components/Form'
import { ANALYTICS_PLACEHOLDER } from './utils'

const Frequency = ({ frequencyPerDay, generateStateMappers, errorField }) => {
  return (
    <>
      <Input
        type="number"
        name="frequencyPerDay"
        label={getMessage('app.popup.frequency')}
        placeholder={getMessage('app.popup.frequency.placeholder')}
        {...generateStateMappers({
          stateKeys: ['view', 'frequencyPerDay'],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        type="number"
        name="interval"
        prefix="minute"
        className="interval"
        readOnly={frequencyPerDay === 1}
        label={getMessage('app.popup.interval')}
        placeholder={getMessage('app.popup.interval.placeholder')}
        {...generateStateMappers({
          stateKeys: ['view', 'intervalInMinutes'],
          loseEmphasisOnFill: true,
        })}
      />
      <Textarea
        type="text"
        name="frequency-analytics"
        label={getMessage('app.popup.analytics')}
        className={errorField.includes('view') && 'input-error'}
        placeholder={ANALYTICS_PLACEHOLDER}
        {...generateStateMappers({
          stateKeys: ['view', 'analytic'],
          loseEmphasisOnFill: true,
        })}
      />
    </>
  )
}

export default Frequency
