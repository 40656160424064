import React from 'react'
import { Input, Select, VALIDATION_TYPES } from '../../../../components/Form'
import { Checkbox, SingleDatePicker } from 'components/Form/Inputs'
import { getMessage } from '../../../../lib/translator'
import './style.scss'
import CategorizedStoreSelector, {
  ALL_OFFLINE_STORES,
  ALL_PREORDER_STORES,
  FAIRPRICE_FFS,
  FAIRPRICE_ONLINE,
  NON_SNG_STORES,
  SNG_STORES,
} from 'components.new/store-selector/CategorizedStoreSelector'
import FlexBox from '../../../../components.new/box/FlexBox'

const CAMPAIGN_TYPES = [
  {
    text: 'Deli',
    value: 'deli',
  },
  {
    text: 'Cash&Carry',
    value: 'cash&carry',
  },
  {
    text: '',
    value: '',
  },
]
export const Divider = () => {
  return (
    <div className="divider-container" data-testid="border">
      <div className="divider-border" />
    </div>
  )
}

export default function CampaignDetails({
  options,
  testid,
  Form,
  generateStateMappers,
  onUpdateStores,
  saveChangesDialogue,
  handleLeavePageCheck,
}) {
  const {
    stores,
    validOrderDateFrom,
    validOrderDateTo,
    validCollectionDateFrom,
    validCollectionDateTo,
    storeId,
    flag,
  } = options
  const dateAndTime = getMessage('preorder.form.dateAndTime.placeholder')
  return (
    <div className="campaignDetails-page">
      <Form>
        <div className="preorderform">
          <h3 className="campaignDetailsHeader">Details</h3>
          <div className="preorder-form-align">
            <div className="preorder-form-align1">
              <Input
                label={getMessage('preorder.form.campaigntitle')}
                placeholder={getMessage(
                  'preorder.form.campaignTitle.placeholder'
                )}
                testid={'campaign-details'}
                name="campaignTitle"
                type="text"
                className="campaignTitle"
                required={!saveChangesDialogue && !handleLeavePageCheck}
                {...generateStateMappers({
                  stateKeys: ['campaignTitle'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
              />
            </div>
            <div className="preorder-form-align1">
              <Select
                label={getMessage('preorder.form.type')}
                placeholder={getMessage('preorder.form.type.placeholder')}
                name="campaignType"
                className="campaignType"
                type="text"
                required={!saveChangesDialogue && !handleLeavePageCheck}
                testid="campaignType"
                controlled
                options={CAMPAIGN_TYPES}
                {...generateStateMappers({
                  stateKeys: ['campaignType'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>

          <FlexBox width="100%" flexDirection="column">
            <label>{getMessage('preorder.form.store')}</label>

            {stores && (stores !== undefined || stores !== 'undefined') && (
              <CategorizedStoreSelector
                stores={stores}
                allowedCategories={[
                  ALL_OFFLINE_STORES,
                  NON_SNG_STORES,
                  FAIRPRICE_ONLINE,
                  FAIRPRICE_FFS,
                  SNG_STORES,
                  ALL_PREORDER_STORES,
                ]}
                values={storeId}
                campaignDetails
                testid={testid}
                onUpdate={onUpdateStores}
              />
            )}
          </FlexBox>
          <Divider />
          <div className="preorder-form-date">
            <Input
              className={'preOrderField'}
              name="leadTime"
              label="LEAD TIME (IN DAYS)"
              type="number"
              required={!saveChangesDialogue && !handleLeavePageCheck}
              placeholder="Enter number of days"
              {...generateStateMappers({
                stateKeys: ['leadTime'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              disabled={flag}
              min={0}
            />
          </div>
          <h3 className="dateTimeHeader">
            {getMessage('preorder.form.dateAndTime.header')}{' '}
          </h3>
          <div className="preorder-form-align-date">
            <div className="preorder-form-date form-field field">
              <label className="validOrderDateFrom">
                {getMessage('preorder.form.dateAndTime.validOrderDate')}{' '}
              </label>
              <SingleDatePicker
                name="validOrderDateFrom"
                label={dateAndTime}
                disabled={flag}
                placeholder={dateAndTime}
                required={
                  validOrderDateFrom ||
                  (!saveChangesDialogue && !handleLeavePageCheck)
                }
                enableToday
                {...generateStateMappers({
                  stateKeys: ['validOrderDateFrom'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </div>
            <div className="preorder-form-date field form-field field">
              <label className="validOrderDateTo">
                {getMessage('preorder.form.dateAndTime.validOrderDateto')}{' '}
              </label>
              <SingleDatePicker
                name="validOrderDateTo"
                label={dateAndTime}
                placeholder={dateAndTime}
                disabled={flag}
                required={
                  validOrderDateTo ||
                  (!saveChangesDialogue && !handleLeavePageCheck)
                }
                enableToday
                {...generateStateMappers({
                  stateKeys: ['validOrderDateTo'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
              {validOrderDateTo && validOrderDateTo < validOrderDateFrom ? (
                <span className="input-error-message">
                  order end date should be greater than start date
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="preorder-form-align-date form-field field">
            <div className="preorder-form-date">
              <label className="validCollectionDateFrom">
                {getMessage('preorder.form.dateAndTime.validCollectionDate')}{' '}
              </label>
              <SingleDatePicker
                name="validCollectionDateFrom"
                label={dateAndTime}
                placeholder={dateAndTime}
                disabled={flag}
                required={
                  validCollectionDateFrom ||
                  (!saveChangesDialogue && !handleLeavePageCheck)
                }
                enableToday
                {...generateStateMappers({
                  stateKeys: ['validCollectionDateFrom'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </div>
            <div className="preorder-form-date form-field field">
              <label className="validCollectionDateTo">
                {getMessage('preorder.form.dateAndTime.validOrderDateto')}{' '}
              </label>
              <SingleDatePicker
                name="validCollectionDateTo"
                label={dateAndTime}
                placeholder={dateAndTime}
                disabled={flag}
                enableToday
                required={
                  validCollectionDateTo ||
                  (!saveChangesDialogue && !handleLeavePageCheck)
                }
                {...generateStateMappers({
                  stateKeys: ['validCollectionDateTo'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </div>
          </div>
          <div className="preorder-form-align-date">
            <div>
              <Input
                name="tag_id"
                label="TAG ID (Note: Applicable to online and hybrid pre order)"
                type="number"
                required={!saveChangesDialogue && !handleLeavePageCheck}
                placeholder="Numeric Value"
                {...generateStateMappers({
                  stateKeys: ['tag_id'],
                  loseEmphasisOnFill: true,
                })}
                min={0}
              />
            </div>
          </div>
          <div className="preorder-form-align-date">
            <div>
              <Input
                name="category_id"
                label="CATEGORY ID (Note: Applicable to online and hybrid pre order)"
                type="number"
                required={!saveChangesDialogue && !handleLeavePageCheck}
                placeholder="Numeric Value"
                {...generateStateMappers({
                  stateKeys: ['category_id'],
                  loseEmphasisOnFill: true,
                })}
                min={0}
              />
            </div>
          </div>
          <Divider />
          <h3 className="dateTimeHeader">Disable SKU scanner (Optional)</h3>
          <div className="preorder-form-align-date">
            <div className="preorder-form-date">
              <Checkbox
                className="availability-status"
                inlineLabel="Disable SKU scanner"
                name={'has_sku_scanner'}
                {...generateStateMappers({
                  stateKeys: ['has_sku_scanner'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>
          <Divider />
          <h3 className="dateTimeHeader">Offer ID (Optional)</h3>
          <div className="preorder-form-align-date">
            <div className="preorder-form-date">
              <Input
                name="offerId"
                type="number"
                label="OFFER ID"
                placeholder="Numeric Value"
                {...generateStateMappers({
                  stateKeys: ['offerId'],
                  loseEmphasisOnFill: true,
                })}
                min={0}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}
