import React from 'react'
import ManageSplashscreenForm from './Form'
import { Link } from 'react-router-dom'
import { tableProperties } from 'components/AppHome/AppSplash/utils'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import './style.css'
import Filters from 'components/AppHome/Filters'

const linkButtons = () => {
  return (
    <div className="header-wrap">
      <Link to="/app-home/splash-dressup/add">
        <button className="primary button">
          <span className="text">Create Splashscreen</span>
        </button>
      </Link>
    </div>
  )
}

const ManageSplashscreen = (props) => {
  return (
    <ListingPageWithRoutes
      testid="appsplash-listing"
      title="Manage Splashscreen"
      addHeading="Create Splashscreen"
      editHeading="Edit Splashscreen"
      menu={props.menu}
      className="appsplash-listingpage"
      api={{
        url: `/genie/lms/splashscreen`,
        transform: (res) => res.items,
      }}
      headerActions={linkButtons}
      emptyState={{ actions: linkButtons }}
      form={{
        component: ManageSplashscreenForm,
      }}
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'splashscreen',
        },
      }}
      tableProperties={tableProperties()}
      viewHeading="Splashscreen Details"
      modalClassName="resend-popup"
      tableDynamic
    />
  )
}

export default ManageSplashscreen
