import React from 'react'
import './style.css'

const Card = ({ src, texts, category }) => {
  return (
    <div className={`${category} card`}>
      <img src={src || ''} alt="Card Image" />
      <div className="texts">
        {Object.values(texts).map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    </div>
  )
}

export default Card
