import React from 'react'
import { getMessage } from 'lib/translator'
import { ANALYTICS_PLACEHOLDER } from './utils'
import { Textarea } from 'components/Form'

const ClickToDismiss = ({ generateStateMappers, errorField }) => {
  return (
    <>
      <h3 className="two-col">{getMessage('app.popup.ctd.button')}</h3>
      <Textarea
        type="text"
        label={getMessage('app.popup.analytics')}
        name="ctd-analytics"
        className={errorField.includes('closeBtn') && 'input-error'}
        placeholder={ANALYTICS_PLACEHOLDER}
        {...generateStateMappers({
          stateKeys: ['closeBtn', 'analytic'],
          loseEmphasisOnFill: true,
        })}
      />
    </>
  )
}

export default ClickToDismiss
