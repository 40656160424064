import API from 'lib/api'
import { getMessage } from 'lib/translator'
import _get from 'lodash.get'

const PAGE = ['view profile', 'create profile', 'draft profile']

const POPUP_HEADING = {
  'edit-module': getMessage(`app.layout.popup.heading.edit-module`),
  'create-module': getMessage(`app.layout.popup.heading.create-module`),
  'create-profile': getMessage(`app.layout.popup.heading.create-profile`),
  'module-type': getMessage(`app.layout.popup.heading.module-type`),
}

const IMG_PLACEHOLDER = {
  'product-swimlane':
    'https://preprod-media.nedigital.sg/fairprice/images/956e7f76-d991-4640-a0f4-a137b624ff4d/product-card-swimlane.png',
  'card-swimlane':
    'https://preprod-media.nedigital.sg/fairprice/images/66f98ae2-accc-4b9a-ae61-5220ed35c0ec/generic-card-swimlane.png',
  banner:
    'https://preprod-media.nedigital.sg/fairprice/images/36ef3cb1-5e0e-42e2-b61f-c00b60eba196/banner.png',
  placeholder:
    'https://preprod-media.nedigital.sg/fairprice/images/39f82ec7-e46a-43aa-8d85-8c118b62bd65/placeholder.png',
}

const getDataSource = async (type) => {
  const res = await new API({
    url: `/genie/lms/datasource?mType=${type}`,
  }).get()

  return res.map((item) => ({
    text: getMessage(`app.layout.module.datasource.${item.api}`),
    value: item.api,
  }))
}

const formatFormData = (data) => {
  if (data.mDataSource !== 'r4u') {
    return data
  }

  const urlParams = _get(data, 'mConfig.default.apiConfig.urlParams') || {}
  return {
    ...data,
    mConfig: {
      default: {
        ...data.mConfig.default,
        apiConfig: {
          urlParams: Object.entries(urlParams).reduce(
            (accumulator, item, index) => {
              accumulator[index] = {
                id: crypto.randomUUID(),
                key: item[0],
                val: item[1],
              }
              return accumulator
            },
            {}
          ),
        },
      },
    },
  }
}

const getConfig = (data) => {
  if (data.mDataSource !== 'r4u') {
    return data.mConfig
  }

  return {
    default: {
      ...data.mConfig.default,
      apiConfig: {
        urlParams: Object.values(
          data.mConfig.default.apiConfig.urlParams
        ).reduce((accumulator, item) => {
          if (item.key) {
            accumulator[item.key] = item.val
          }
          return accumulator
        }, {}),
      },
    },
  }
}

export {
  getDataSource,
  formatFormData,
  getConfig,
  IMG_PLACEHOLDER,
  PAGE,
  POPUP_HEADING,
}
