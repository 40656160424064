import React from 'react'
import CardContentForm from 'components/AppHome/AppCard/CardContentForm'
import CardCategoryForm from 'components/AppHome/AppCard/CardCategoryForm'
import CardNameDeeplink from 'components/AppHome/AppCard/CardNameDeeplink'
import CardIDCategory from 'components/AppHome/AppCard/CardIDCategory'
import { VARIANT } from 'components/AppHome/AppCard/utils'
import { formatValidity } from 'components/AppHome/utils'
import Card from 'components/AppHome/AppCard/Card'
import Validity from 'components/AppHome/Validity'
import { getMessage } from 'lib/translator'
import { BaseForm } from 'components/Form'
import { Dialog } from 'components/Popup'
import Loader from 'components/Loader'
import API from 'lib/api'
import './style.css'

class ManageCardForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      formType: null,
      previewCard: false,
      showErrorDialog: false,
      errorMessage: null,
      readOnlyField: false,
      isPublishing: false,
      values: {
        startTime: '00:00:00',
        endTime: '00:00:00',
        texts: {
          text1: '',
        },
      },
    }
  }

  async componentDidMount() {
    const { method } = this.props
    if (method === 'edit') {
      this.setState({ formType: 'cardForm', loading: true })
      const { value } = this.props
      const validity = await formatValidity('api', value)
      const values = {
        ...value,
        ...validity,
      }
      this.setState({ values, loading: false })
    } else {
      this.setState({ formType: 'selectCardType' })
    }
  }

  async _submitHandler(e, isEnable = true) {
    e && e.preventDefault()
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const req = this.props.method === 'add' ? 'post' : 'put'
    if (this.isFormValid()) {
      const api = new API({
        url:
          '/genie/lms/cards' + (req === 'put' ? '/' + this.props.value.id : ''),
      })
      const validity = await formatValidity('form', this.state.values)
      api[req]({
        ...this.state.values,
        ...validity,
        isEnable,
      }).then(() => {
        window.location.reload()
      })
    }
  }

  render() {
    const { Form } = this.components
    const { CancelButton, SubmitButton } = this.buttons
    const { showErrorDialog, formType, loading, previewCard } = this.state

    return (
      <>
        {showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            information={this.state.errorMessage}
            close={() =>
              this.setState({
                showErrorDialog: false,
                errorMessage: null,
              })
            }
            closeText={getMessage('apphome.okay')}
          />
        )}

        {formType === 'cardForm' && (
          <div className="appcard-form">
            {loading && <Loader size="sm" />}
            {!loading && (
              <Form>
                <CardIDCategory
                  method={this.props.method}
                  id={this.state.values.id}
                  category={this.state.values.category}
                />
                {previewCard && (
                  <Card
                    src={this.state.values.image}
                    category={this.state.values.category}
                    texts={this.state.values.texts}
                  />
                )}
                {!previewCard && (
                  <CardContentForm
                    category={this.state.values.category}
                    generateStateMappers={this.generateStateMappers}
                  />
                )}
                <button
                  type="button"
                  className="button preview-button"
                  onClick={() =>
                    this.setState({ previewCard: !this.state.previewCard })
                  }
                >
                  {!previewCard
                    ? getMessage('app.card.preview')
                    : getMessage('apphome.back')}
                </button>
                <hr />
                <CardNameDeeplink
                  generateStateMappers={this.generateStateMappers}
                />
                <Validity
                  readOnlyField={false}
                  generateStateMappers={this.generateStateMappers}
                />
                <div className="appFormButtons">
                  <>
                    <CancelButton>{getMessage('apphome.cancel')}</CancelButton>
                  </>
                  <button
                    type="button"
                    className="primary"
                    data-testid="save-button"
                    onClick={(e) => this._submitHandler(e, false)}
                  >
                    {getMessage('apphome.saveasdraft')}
                  </button>
                  <SubmitButton>{getMessage('apphome.enable')}</SubmitButton>
                </div>
              </Form>
            )}
          </div>
        )}

        {formType === 'selectCardType' && (
          <CardCategoryForm
            onSelected={(val) => {
              this.setState({
                formType: 'cardForm',
                values: {
                  ...this.state.values,
                  category: val,
                  variant: VARIANT[val],
                },
              })
            }}
          />
        )}
      </>
    )
  }
}

export default ManageCardForm
