import React from 'react'
import { getMessage } from 'lib/translator'
import Draggable from '../Draggable'
import Card from './Card'
import API from 'lib/api'

const PreviewCard = ({ previewCards, isSorted, closePopup, moveCard }) => {
  const updateCardPosition = () => {
    new API({ url: `/genie/lms/cards/position` })
      .put(
        previewCards.map((card) => ({
          id: card.id,
          category: card.category,
          priority: card.priority,
        }))
      )
      .then(() => {
        closePopup()
        window.location.reload()
      })
  }

  return previewCards.length > 0 ? (
    <div className="preview-container">
      <div className="filteredcards-section">
        {previewCards.map((card, index) => (
          <Draggable
            index={index}
            key={card.id}
            axis="horizontal"
            moveCard={moveCard}
          >
            <Card
              src={card.image}
              texts={card.texts || []}
              category={card.category}
            />
          </Draggable>
        ))}
      </div>
      {!isSorted && (
        <p className="text-section">{getMessage('app.card.change.position')}</p>
      )}
      {isSorted && (
        <div className="buttons-section">
          <button className="button" onClick={closePopup}>
            <span className="text">{getMessage('apphome.cancel')}</span>
          </button>
          <button className="primary button" onClick={updateCardPosition}>
            <span className="text">{getMessage('apphome.enable')}</span>
          </button>
        </div>
      )}
    </div>
  ) : (
    <p className="empty text-section">{getMessage('app.card.empty')}</p>
  )
}

export default PreviewCard
