import { fetchAuthToken } from '../okta'
import { getOktaState } from '../utils'
import { inMemoryStore } from '../../store'
import { get, set } from 'lib/storage'

// Features:
// 1. accept token
// 2. save token to storage
// 3. auto-load token from storage

const saveSession = function (sessionData) {
  for (const key in sessionData) {
    // Don't save the token from Auth API and rather use okta access token
    if (key === 'token' && getOktaState()) {
      continue
    }
    if (sessionData[key]) {
      if (key === 'user') {
        savePermissions(sessionData[key].endpointPermissions)
        delete sessionData[key].endpointPermissions
        inMemoryStore.setItem('user', JSON.stringify(sessionData[key]), true)
        continue
      }
      if (key === 'organization') {
        inMemoryStore.setItem(
          'organization',
          JSON.stringify(sessionData[key]),
          true
        )
        continue
      }
      window.localStorage.setItem(
        key,
        typeof sessionData[key] === 'object'
          ? JSON.stringify(sessionData[key])
          : sessionData[key]
      )
    }
  }
}

// Checks if user has permissions to make a request to server
// For endpoint like order-service/order-log with method PUT
// service = order, endpoint = orrder-log, method = put/PUT
const hasPermissions = (service, endpoint, method, isNewService = false) => {
  if (!service || !endpoint || !method) {
    return false
  }

  let permissions = JSON.parse(inMemoryStore.getItem('formattedPermissions'))

  if (!permissions) {
    permissions = getAllPermissions()

    inMemoryStore.setItem('formattedPermissions', JSON.stringify(permissions))
  }
  let key = `${service}-service/${endpoint}`
  if (isNewService) {
    key = `${service}/${endpoint}`
  }
  return permissions[key] && permissions[key][method.toUpperCase()]
}

const savePermissions = function (permissions = []) {
  const formatted = Object.assign(
    {},
    ...permissions.map((permission) => {
      return {
        [permission.url]: permission,
      }
    })
  )

  inMemoryStore.setItem('permissions', JSON.stringify(formatted))

  const formattedPermissions = getAllPermissions()
  inMemoryStore.setItem(
    'formattedPermissions',
    JSON.stringify(formattedPermissions)
  )
}

const getPermissions = function (endpoints = []) {
  const permissions = JSON.parse(inMemoryStore.getItem('permissions'))

  if (!permissions) {
    return false
  }

  return Object.assign(
    {},
    ...endpoints.map((endpoint) => {
      if (endpoint in permissions) {
        return {
          [endpoint]: permissions[endpoint].allowedMethods,
        }
      } else {
        return {
          [endpoint]: false,
        }
      }
    })
  )
}

const getAllPermissions = function () {
  const permissions = JSON.parse(inMemoryStore.getItem('permissions'))

  return Object.assign(
    {},
    ...Object.keys(permissions).map((endpoint) => ({
      [endpoint]: permissions[endpoint]['allowedMethods'],
    }))
  )
}
export const parseFormattedPermission = function (permissions) {
  return Object.assign(
    {},
    ...Object.keys(permissions).map((endpoint) => ({
      [endpoint]: permissions[endpoint]['allowedMethods'],
    }))
  )
}

const getAuthToken = function () {
  let token
  if (!getOktaState()) {
    token = window.localStorage.getItem('token')
  } else {
    token = fetchAuthToken()
  }
  return token
}

const getSession = function () {
  return {
    organization: JSON.parse(getOrganization()),
    user: JSON.parse(getUser()),
  }
}

const getTierSetups = () => {
  return JSON.parse(get('tierSetups'))
}

const getStores = () => {
  const stores = inMemoryStore.getItem('stores') || null
  return JSON.parse(stores)
}

const sortStores = (storesArr) => {
  return storesArr.sort((a, b) => {
    if (a.text > b.text) {
      return 1
    } else if (b.text > a.text) {
      return -1
    } else {
      return 0
    }
  })
}

const setTierSetups = (stores) => {
  return set('tierSetups', JSON.stringify(stores))
}

const setStores = (stores) => {
  inMemoryStore.setItem('stores', JSON.stringify(stores))
}

const clearSession = function () {
  const organizationName = window.localStorage.getItem('organization-name')
  const language = window.localStorage.getItem('language')
  window.localStorage.clear()
  if (organizationName && organizationName !== 'null') {
    window.localStorage.setItem('organization-name', organizationName)
  }
  window.localStorage.setItem('language', language)
}

const isLoggedIn = function () {
  return Boolean(getAuthToken())
}

const isAccountVerified = function () {
  const session = getSession()
  return session.user.verified
}

const verifyAccount = function () {
  const session = getSession()
  session.user.verified = true
  saveSession(session)
}

const isExtensionEnabled = function (slugOrId) {
  const organization = getOrganization() ? JSON.parse(getOrganization()) : ''
  if (!organization) {
    return false
  }
  let enabled
  if (typeof slugOrId === 'string') {
    const slug = slugOrId
    enabled =
      (organization.extension || []).findIndex((ext) => ext.slug === slug) > -1
  } else {
    const id = slugOrId
    enabled =
      (organization.extension || []).findIndex((ext) => ext.id === id) > -1
  }
  return enabled
}

const disableExtension = function (extension) {
  const organization = JSON.parse(getOrganization())
  if (!organization.extension) {
    organization.extension = []
  }
  organization.extension = organization.extension.filter(
    (ext) => ext.id !== extension.id
  )
  inMemoryStore.setItem('organization', JSON.stringify(organization))
}

const enableExtension = function (extension) {
  const organization = JSON.parse(getOrganization())
  if (!organization.extension) {
    organization.extension = []
  }
  organization.extension.push(extension)
  inMemoryStore.setItem('organization', JSON.stringify(organization))
}

const isEnterprise = () => {
  const userInfo = getSession()
  return Boolean(userInfo.organization && userInfo.organization.isEnterprise)
}

const modifyMenuForEnterprise = (menu) => {
  if (!isEnterprise()) {
    return menu
  }
  const newMenu = { ...menu }
  if (!isExtensionEnabled('Marketing')) {
    delete newMenu.marketing
  }
  return newMenu
}

const setUrl = (url) => {
  window.localStorage.setItem('url', url)
}

const getUrl = () => {
  const url =
    localStorage.getItem('url') !== null
      ? window.localStorage.getItem('url')
      : ''
  if (url === '') {
    return null
  }
  return url
}

// TODO: Get this from API response
// const configureableExtensions = [
//   'MultiUserSupport',
//   'MultiStoreSupport',
//   'InStoreProcessing',
//   'LogisticsSupport',
//   'DeliveryAreaSupport',
//   'Analytics',
//   'TawkToLiveChat',
//   'Seo',
//   'OnlinePaymentSupport',
//   'EntityMetaData',
//   'DeliverySlots',
//   'CustomerTags',
//   'StockOverride',
//   'SearchOverride'
// ]

const getConfigureableExtensions = () => {
  const extensions = []
  if (hasPermissions('account', 'store', 'get')) {
    extensions.push('MultiStoreSupport')
  }
  if (hasPermissions('account', 'user', 'get')) {
    extensions.push('MultiUserSupport')
  }
  if (hasPermissions('account', 'extension', 'get')) {
    extensions.push('InStoreProcessing')
  }
  if (hasPermissions('logistics', 'delivery-area', 'get')) {
    extensions.push('DeliveryAreaSupport')
  }
  if (hasPermissions('account', 'config', 'get')) {
    extensions.push('LogisticsSupport')
  }
  if (hasPermissions('account', 'config', 'get')) {
    extensions.push('Analytics')
  }
  if (hasPermissions('account', 'extension', 'get')) {
    extensions.push('TawkToLiveChat')
  }
  if (hasPermissions('account', 'seo', 'get')) {
    extensions.push('Seo')
  }
  if (hasPermissions('config', 'meta-data', 'get')) {
    extensions.push('EntityMetaData')
  }
  if (
    hasPermissions('account', 'extension', 'get') &&
    hasPermissions('order', 'slot', 'get')
  ) {
    extensions.push('DeliverySlots')
  }
  if (hasPermissions('customer', 'tag', 'get')) {
    extensions.push('CustomerTags')
  }
  if (hasPermissions('catalogue', 'product-ranking', 'get')) {
    extensions.push('SearchOverride')
  }
  return extensions
}

const slugs = {
  MultiUserSupport: 'users',
  MultiStoreSupport: 'stores',
  MultiVariantSupport: 'variants',
  InStoreProcessing: 'instore',
  DeliveryAreaSupport: 'delivery-area',
  Analytics: 'analytics',
  TawkToLiveChat: 'tawk',
  Seo: 'seo',
  OnlinePaymentSupport: 'payment-configuration',
  EntityMetaData: 'metadata',
  DeliverySlots: 'slots',
  LogisticsSupport: 'logistics-configuration',
  CustomerTags: 'customer-tags',
  SearchOverride: 'search-configuration',
}

let firstSubmenu = ''

const modifyEnterprisePermissions = () => {
  const extensions =
    getSession().organization && getSession().organization.extension
  const configureableExtensions = getConfigureableExtensions()
  const menu = extensions
    .map((extension) => {
      if (configureableExtensions.indexOf(extension.slug) > -1) {
        if (firstSubmenu === '') {
          firstSubmenu = slugs[extension.slug]
        }
        return {
          slug: slugs[extension.slug],
          extensions: [extension.slug],
        }
      }
      return null
    })
    .filter(Boolean)

  if (
    getSession().organization.domain &&
    hasPermissions('account', 'theme', 'get') &&
    hasPermissions('website', 'layout', 'get')
  ) {
    menu.push({
      slug: 'themes',
    })
  }
  if (hasPermissions('config', 'config', 'GET')) {
    menu.push({
      slug: 'communication-configuration',
    })
  }
  return menu
}
const getExtensionDetails = (slug) => {
  const organization = JSON.parse(getOrganization())
  if (!organization) {
    return null
  }
  const index = (organization.extension || []).findIndex(
    (ext) => ext.slug === slug
  )
  if (index > -1) {
    return organization.extension[index]
  }
  return null
}

const saveEntityMetaData = (entityMetaDataConfig) => {
  window.localStorage.setItem(
    'entityMetaDataConfig',
    JSON.stringify(entityMetaDataConfig)
  )
}

const getEntityMetaData = () => {
  return JSON.parse(window.localStorage.getItem('entityMetaDataConfig'))
}

const getStoreSelect = () => {
  const stores = getStores()
  return (
    stores &&
    stores.map((store) => {
      return {
        text: store.name,
        value: store.id,
      }
    })
  )
}

const getStoreIds = () => {
  const stores = getStores()
  return stores && stores.map((store) => store.id)
}

const getSelectedStore = () => {
  const stores = getStores()
  if (stores) {
    const storeId = get('store')
    return stores.find((x) => x.id.toString() === storeId)
  }
  return null
}

const extensionRoutes = {
  OnlinePaymentSupport: '/settings/payment-configuration',
  Seo: '/settings/seo',
  DeliveryAreaSupport: '/settings/delivery-area',
  MultiStoreSupport: '/settings/stores',
  MultiUserSupport: '/settings/users',
  DeliverySlots: '/settings/slots',
  LogisticsSupport: '/settings/logistics-configuration',
  CustomerTags: '/settings/customer-tags',
  SearchOverride: '/settings/search-configuration',
}

export const getUser = () => {
  return inMemoryStore.getItem('user')
}

export const getOrganization = () => {
  return inMemoryStore.getItem('organization')
}

export const getDefaultStoreId = () => {
  return JSON.parse(getOrganization() || '{}').defaultStoreId
}

export {
  getOktaState,
  clearSession,
  disableExtension,
  enableExtension,
  getAllPermissions,
  getAuthToken,
  getPermissions,
  getSession,
  isExtensionEnabled,
  isLoggedIn,
  isAccountVerified,
  saveSession,
  verifyAccount,
  isEnterprise,
  modifyMenuForEnterprise,
  saveEntityMetaData,
  getEntityMetaData,
  getExtensionDetails,
  extensionRoutes,
  modifyEnterprisePermissions,
  firstSubmenu,
  getTierSetups,
  setTierSetups,
  getStores,
  setStores,
  hasPermissions,
  getStoreSelect,
  getStoreIds,
  sortStores,
  setUrl,
  getUrl,
  getSelectedStore,
}
