import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import { Select } from '../../../components/Form'
import { Popup, Dialog } from '../../../components/Popup'
import { getStores } from '../../../lib/auth'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import PincodeFormComponent from './FormComponents/Pincode'
import RadialFormComponent from './FormComponents/Radial'
import PolygonFormComponent from './FormComponents/Polygon'
import CommonMapComponent from './CommonMapComponent'
import AddDeliveryArea from './AddDeliveryArea'
import KMLUpload from './KMLUpload'

import './style.css'
import { get } from 'lib/storage'

export const distinctColors = [
  { color: 'default', value: '#7ac8ed' },
  { color: 'blue', value: '#0000ff' },
  { color: 'maroon', value: '#800000' },
  { color: 'darkolivegreen', value: '#556b2f' },
  { color: 'darkblue', value: '#00008b' },
  { color: 'darkmagenta', value: '#8b008b' },
  { color: 'darkvoilet', value: '#eca5b7' },
  { color: 'darkorchid', value: '#9932cc' },
  { color: 'darkcyan', value: '#008b8b' },
  { color: 'darkgreen', value: '#006400' },
  { color: 'darkorange', value: '#ff8c00' },
  { color: 'voilet', value: '#9ca2e0' },
]

export class MapControls extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showStoreError: false,
      pincodeOpen: false,
      polygonOpen: false,
      radialOpen: false,
    }
    this.storeSelectReadOnly = false
    this.pincodeComp = React.createRef()
    this.radialComp = React.createRef()
    this.polygonComp = React.createRef()

    this.handleEditCancel = this.handleEditCancel.bind(this)
    this.handleOpenClose = this.handleOpenClose.bind(this)
    this.clearAllTempValues = this.clearAllTempValues.bind(this)
    this.setPincodeGroupForEdit = this.setPincodeGroupForEdit.bind(this)
    this.setRadialZoneForEdit = this.setRadialZoneForEdit.bind(this)
    this.toggleStoreError = this.toggleStoreError.bind(this)
  }

  clearAllTempValues() {
    this.pincodeComp &&
      this.pincodeComp.current &&
      this.pincodeComp.current.clearTempData()
    this.radialComp &&
      this.radialComp.current &&
      this.radialComp.current.clearTempData()
    this.polygonComp &&
      this.polygonComp.current &&
      this.polygonComp.current.clearTempData()
  }
  handleEditCancel() {
    const { changeEditMode, editing, clearTempData } = this.props
    editing && changeEditMode()
    this.props.setStore(null)
    this.setState({ showStoreError: false })
    this.clearAllTempValues()
    clearTempData()
  }
  handleOpenClose(section, open) {
    this.setState((prevState) => {
      return { [`${section}Open`]: open ? true : !prevState[`${section}Open`] }
    })
  }
  toggleStoreError() {
    const { showStoreError } = this.state
    this.setState({ showStoreError: !showStoreError })
  }
  setPincodeGroupForEdit(valueArr, name, groupId) {
    this.pincodeComp.current.changeMode('edit')
    this.pincodeComp.current.changePincodeGroupEditId(groupId)
    this.pincodeComp.current.setPincodeInput(valueArr, name)
  }
  setRadialZoneForEdit(startRadius, endRadius, name, groupId) {
    this.radialComp.current.changeMode('edit')
    this.radialComp.current.changeRadialZoneEditId(groupId)
    this.radialComp.current.setStartEndRadius(startRadius, endRadius)
    this.radialComp.current.setZoneName(name)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.editing && nextProps.storeId) {
      if (!this.props.editing && this.props.storeId) {
        this.storeSelectReadOnly = true
      }
    }
    if (!nextProps.editing) {
      this.storeSelectReadOnly = false
    }
  }
  render() {
    const {
      parent,
      deliveryArea = [],
      editing,
      selectedLocation,
      deleteSelectedLocation,
      editPolygonId,
      setPolygonName,
      storeId,
      saveData,
      setNewLocation,
      handlePincodeDelete,
      handleZoneDelete,
      setEditPolygonId,
    } = this.props
    const pincodeArea = deliveryArea.filter(
      (zone) => zone.configType === 'PINCODE'
    )
    const radialArea = deliveryArea.filter(
      (zone) => zone.configType === 'RADIAL'
    )
    const polygonalArea = deliveryArea.filter(
      (zone) => zone.configType === 'POLYGON'
    )
    const allStores =
      getStores() &&
      getStores()
        .filter((store) => store.hasDeliveryHub)
        .map((store) => {
          return { text: store.name, value: store.id }
        })
    !editing && allStores.unshift({ text: 'All stores', value: 'ALL' })
    return (
      <React.Fragment>
        <div
          className="form-fields"
          style={{ marginBottom: editing ? '80px' : 0 }}
        >
          {allStores && (
            <Select
              label="Store"
              placeholder={editing ? 'Select store' : ''}
              name="store"
              options={allStores}
              readOnly={this.storeSelectReadOnly}
              value={storeId}
              onChange={(id) => {
                this.setState({ showStoreError: false })
                this.props.setStore(id)
              }}
            />
          )}
          {this.state.showStoreError && (
            <small className="store-select-error">
              {getMessage('extensions.deliveryArea.store.error')}
            </small>
          )}
          <div className="horizontal-line">
            <hr />
          </div>
          <h3 className="delivering-zones title">
            {getMessage('extensions.deliveryArea.zones.heading')}
          </h3>
          {(editing || !!pincodeArea.length) && (
            <PincodeFormComponent
              ref={this.pincodeComp}
              _this={this}
              pincodeArea={pincodeArea}
              storeId={this.props.storeId}
              active={this.state.pincodeOpen}
              handleOpenClose={this.handleOpenClose}
              handleZoneDelete={handleZoneDelete}
              handlePincodeDelete={handlePincodeDelete}
              editing={editing}
              parent={parent}
            />
          )}
          {(editing || !!radialArea.length) && (
            <RadialFormComponent
              ref={this.radialComp}
              _this={this}
              radialArea={radialArea}
              storeId={this.props.storeId}
              active={this.state.radialOpen}
              handleOpenClose={this.handleOpenClose}
              editing={editing}
              parent={parent}
              selectedLocation={selectedLocation}
              setNewLocation={setNewLocation}
              deleteSelectedLocation={deleteSelectedLocation}
              distinctColors={distinctColors}
              handleZoneDelete={handleZoneDelete}
            />
          )}
          {(editing || !!polygonalArea.length) && (
            <PolygonFormComponent
              ref={this.polygonComp}
              _this={this}
              polygonalArea={polygonalArea}
              storeId={this.props.storeId}
              active={this.state.polygonOpen}
              handleOpenClose={this.handleOpenClose}
              editing={editing}
              parent={parent}
              setPolygonName={setPolygonName}
              editPolygonId={editPolygonId}
              setEditPolygonId={setEditPolygonId}
              distinctColors={distinctColors}
              handleZoneDelete={handleZoneDelete}
            />
          )}
        </div>
        {/* <SubmitButton disabled={this.state.submitting}>{getMessage('basicInfo.submit.text')}</SubmitButton> */}
        {this.props.editing && (
          <div className="action-buttons">
            <div>
              <button onClick={this.handleEditCancel} className="button">
                {getMessage('extensions.deliveryArea.cancel')}
              </button>
              <button onClick={saveData} className="button primary">
                {getMessage('extensions.deliveryArea.save')}
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export class InterfaceContainer extends Component {
  constructor(props) {
    super(props)
    this.setStore = this.setStore.bind(this)
    this.setNewLocation = this.setNewLocation.bind(this)
    this.deleteSelectedLocation = this.deleteSelectedLocation.bind(this)
    this.setNewLocations = this.setNewLocations.bind(this)
    this.setNewEditedLocations = this.setNewEditedLocations.bind(this)
    this.setPolygonName = this.setPolygonName.bind(this)
    this.setEditPolygonId = this.setEditPolygonId.bind(this)
    this.fetchDeliveryAreaData = this.fetchDeliveryAreaData.bind(this)
    this.saveData = this.saveData.bind(this)
    this.changeEditMode = this.changeEditMode.bind(this)
    this.clearTempData = this.clearTempData.bind(this)
    this.setEditId = this.setEditId.bind(this)
    this.handlePincodeDelete = this.handlePincodeDelete.bind(this)
    this.handlePincodeEdit = this.handlePincodeEdit.bind(this)
    this.handleZoneDelete = this.handleZoneDelete.bind(this)
    this.handleRadialZoneEdit = this.handleRadialZoneEdit.bind(this)
    this.handleEditValuesOpen = this.handleEditValuesOpen.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.uploadSuccessCallback = this.uploadSuccessCallback.bind(this)
    this.setEditedTempPolygons = this.setEditedTempPolygons.bind(this)
    this.setNewTempPolygons = this.setNewTempPolygons.bind(this)
    this.mapControlsRef = React.createRef()

    this.state = {
      deliveryarea: [],
      tempDeliveryArea: [],
      storeId:
        localStorage.getItem('prevEdittingStoreMapId') ||
        JSON.parse(get('organization') || '{}').defaultStoreId ||
        'ALL',
      values: null,
      editing: false,
      selectedLocation: null,
      editPolygonId: null,
      showError: false,
      apiError: null,
      pincodeEditId: null,
      radialEditId: null,
      kmlUploadPopupShown: false,
      showSelectStoreDialog: false,
    }
  }
  showModal() {
    if (!this.state.storeId) {
      this.setState({ showSelectStoreDialog: true })
      return
    }
    this.setState({
      kmlUploadPopupShown: true,
    })
  }
  hideModal() {
    this.setState({
      kmlUploadPopupShown: false,
    })
  }
  uploadSuccessCallback(response) {
    this.hideModal()
    this.setState({ editing: true }, () => {
      const currentState = JSON.parse(JSON.stringify(this.state))
      const { tempDeliveryArea, deliveryarea } = currentState
      response.data.deliveryAreas.map((area) => {
        if (area.id) {
          this.setEditedTempPolygons({
            locations: area.locations,
            id: area.id,
            name: area.name,
            latKeyName: 'latitude',
            lngKeyName: 'longitude',
            tempDeliveryArea,
            deliveryarea,
          })
        } else {
          this.setNewTempPolygons({
            locations: area.locations,
            name: area.name,
            latKeyName: 'latitude',
            lngKeyName: 'longitude',
            tempDeliveryArea,
          })
        }
        return null
      })
      this.setState({ tempDeliveryArea })
    })
  }
  setNewLocation(lat, lng) {
    this.setState({
      selectedLocation: { lat, lng },
    })
  }
  saveData() {
    const api = new API({ url: '/logistics-service/delivery-area' })
    const tempDeliveryArea = JSON.parse(
      JSON.stringify(this.state.tempDeliveryArea)
    )
    const storeId = this.state.storeId
    if (!storeId && !tempDeliveryArea.every((zone) => zone.delete || zone.id)) {
      this.mapControlsRef.current.toggleStoreError()
    } else {
      const allApiCalls = []
      tempDeliveryArea
        .filter((tempArea) => !tempArea.delete)
        .map((tempArea) => {
          const mode = tempArea.tempId ? 'add' : 'edit'
          const editApi = new API({
            url: `/logistics-service/delivery-area/${tempArea.id}`,
          })
          const params = {}
          params['configType'] = tempArea.configType
          params['storeId'] = mode === 'add' ? Number(storeId) : tempArea.storeId
          params['name'] = tempArea.name || ''
          if (mode === 'edit') {
            params['id'] = tempArea.id
          }
          if (tempArea.configType === 'PINCODE') {
            params['pincodes'] = tempArea.area.pincodes
            if (mode === 'edit') {
              params['pincodes'] = tempArea.area.pincodes.map((pincodeObj) => {
                const { id, pincode } = pincodeObj
                return {
                  pincode: pincode,
                  ...(id && { id }),
                }
              })
            }
          }
          if (tempArea.configType === 'RADIAL') {
            params['startRadius'] = tempArea.area.startRadius
            params['endRadius'] = tempArea.area.endRadius
            params['latitude'] = tempArea.area.latitude
            params['longitude'] = tempArea.area.longitude
            params['unit'] = tempArea.area.unit
          }
          if (tempArea.configType === 'POLYGON') {
            params['locations'] = tempArea.area.locations
          }
          mode === 'add' && allApiCalls.push(api.post(params))
          mode === 'edit' && allApiCalls.push(editApi.put(params))
          return null
        })
      tempDeliveryArea
        .filter((tempArea) => tempArea.delete && !tempArea.tempId)
        .map((tempArea) => {
          const params = {}
          const deleteApi = new API({
            url: `/logistics-service/delivery-area/${tempArea.id}`,
          })
          params['id'] = tempArea.id
          allApiCalls.push(deleteApi.delete(params))
          return null
        })

      Promise.all(allApiCalls)
        .then(() => {
          this.setState({ tempDeliveryArea: [], editing: false })

          localStorage.setItem('prevEdittingStoreMapId', this.state.storeId)

          this.props.history.push('/settings/delivery-area')
        })
        .catch((error) => {
          if (error.code === '401' || error.code === '403') {
            throw error
          }
          this.setState(
            { showError: true, apiError: error.message, tempDeliveryArea: [] },
            () => this.fetchDeliveryAreaData()
          )
        })
    }
  }

  deleteSelectedLocation() {
    this.setState({ selectedLocation: null })
  }

  setStore(storeId) {
    this.setState({ storeId })
    if (!this.state.editing) {
      this.fetchDeliveryAreaData(storeId)
    }
  }
  handleEditValuesOpen(section) {
    this.mapControlsRef.current.handleOpenClose(section, true)
  }
  changeEditMode() {
    const editing = !this.state.editing
    this.setState({ editing })
  }
  clearTempData() {
    this.setState({ tempDeliveryArea: [] })
  }
  handlePincodeDelete(groupId, pincode) {
    const currentState = JSON.parse(JSON.stringify(this.state))
    const { tempDeliveryArea: tempDeliveryAreaProp, deliveryarea } =
      currentState
    let tempDeliveryArea = tempDeliveryAreaProp
    const pincodeGroup = tempDeliveryArea.find(
      (zone) => zone.id === groupId || zone.tempId === groupId
    )
    if (pincodeGroup) {
      if (pincodeGroup.area.pincodes.length === 1) {
        return false
      }
      const newTempDeliveryArea = tempDeliveryArea.filter(
        (zone) => zone.id !== groupId && zone.tempId !== groupId
      )
      const newTempPincodeArray = pincodeGroup.area.pincodes.filter(
        (pincodeObj) => pincodeObj.pincode !== pincode
      )
      pincodeGroup.area.pincodes = newTempPincodeArray
      newTempDeliveryArea.push(pincodeGroup)
      tempDeliveryArea = newTempDeliveryArea
    } else {
      const pincodeGroupFromState = deliveryarea.find(
        (zone) => zone.id === groupId
      )
      if (pincodeGroupFromState) {
        if (pincodeGroupFromState.area.pincodes.length === 1) {
          return false
        }
        const newTempPincodeArray = pincodeGroupFromState.area.pincodes.filter(
          (pincodeObj) => pincodeObj.pincode !== pincode
        )
        pincodeGroupFromState.area.pincodes = newTempPincodeArray
        tempDeliveryArea.push(pincodeGroupFromState)
      }
    }
    this.setState({ tempDeliveryArea })
    return true
  }
  handlePincodeEdit(groupId) {
    let pincodeGroup = this.state.tempDeliveryArea.find(
      (zone) => zone.id === groupId || zone.tempId === groupId
    )
    pincodeGroup =
      pincodeGroup ||
      this.state.deliveryarea.find((zone) => zone.id === groupId)
    const name = pincodeGroup.name
    const valueArr = pincodeGroup.area.pincodes.map(
      (pincodeObj) => pincodeObj.pincode
    )
    this.mapControlsRef.current.handleOpenClose('pincode', true)
    this.mapControlsRef.current.setPincodeGroupForEdit(valueArr, name, groupId)
  }
  handleRadialZoneEdit(groupId) {
    if (this.state.editing) {
      let radialZone = this.state.tempDeliveryArea.find(
        (zone) => zone.id === groupId || zone.tempId === groupId
      )
      radialZone =
        radialZone || this.state.deliveryarea.find((zone) => zone.id === groupId)
      const { startRadius, endRadius } = radialZone.area
      const name = radialZone.name
      this.mapControlsRef.current.handleOpenClose('radial', true)
      this.setNewLocation(
        radialZone.area.markerLocation.lat,
        radialZone.area.markerLocation.lng
      )
      this.mapControlsRef.current.setRadialZoneForEdit(
        startRadius,
        endRadius,
        name,
        groupId
      )
    }
  }
  handleZoneDelete(groupId) {
    const currentState = JSON.parse(JSON.stringify(this.state))
    const { tempDeliveryArea: tempDeliveryAreaProp, deliveryarea } =
      currentState
    let tempDeliveryArea = tempDeliveryAreaProp
    const zone = tempDeliveryArea.find(
      (singleZone) => singleZone.id === groupId || singleZone.tempId === groupId
    )
    if (zone) {
      zone.delete = true
      const newTempDeliveryArea = tempDeliveryArea.filter(
        (singleZone) =>
          singleZone.id !== groupId && singleZone.tempId !== groupId
      )
      newTempDeliveryArea.push(zone)
      tempDeliveryArea = newTempDeliveryArea
    } else {
      const newZone = deliveryarea.find(
        (singleZone) => singleZone.id === groupId
      )
      if (newZone) {
        newZone.delete = true
        tempDeliveryArea.push(newZone)
      }
    }
    this.setState({ tempDeliveryArea })
  }
  setEditId(type, id) {
    this.setState({ [`${type}EditId`]: id })
  }
  setNewLocations(params) {
    const randomId = new Date().getTime()
    const currentState = JSON.parse(JSON.stringify(this.state))
    const { tempDeliveryArea } = currentState
    this.setNewTempPolygons({ ...params, tempDeliveryArea, randomId })
    this.mapControlsRef.current.handleOpenClose('polygon', true)
    this.setState({
      tempDeliveryArea,
      editPolygonId: randomId,
    })
  }
  setNewTempPolygons({
    locations,
    name,
    latKeyName,
    lngKeyName,
    tempDeliveryArea,
    randomId = new Date().getTime(),
  }) {
    tempDeliveryArea.push({
      configType: 'POLYGON',
      name: name || '',
      tempId: randomId,
      area: {
        locations:
          locations?.map((coord) => {
            return {
              latitude: latKeyName ? coord[latKeyName] : coord.lat,
              longitude: lngKeyName ? coord[lngKeyName] : coord.lng,
            }
          }) || [],
      },
    })
  }

  setPolygonName(id, name) {
    const currentState = JSON.parse(JSON.stringify(this.state))
    const { tempDeliveryArea, deliveryarea } = currentState
    let flag = false
    tempDeliveryArea.forEach((zone) => {
      if (zone.id === id || zone.tempId === id) {
        zone.name = name
        flag = true
      }
    })
    !flag &&
      deliveryarea.map((zone) => {
        if (zone.id === id) {
          tempDeliveryArea.push({ ...zone, name })
          flag = true
        }
        return null
      })
    flag && this.setState({ tempDeliveryArea, editPolygonId: null })
  }

  setEditPolygonId(id) {
    this.setState({ editPolygonId: id })
  }

  setNewEditedLocations(params) {
    const currentState = JSON.parse(JSON.stringify(this.state))
    const { tempDeliveryArea, deliveryarea } = currentState
    this.setEditedTempPolygons({ ...params, tempDeliveryArea, deliveryarea })
  }
  setEditedTempPolygons({
    locations,
    id,
    name,
    latKeyName,
    lngKeyName,
    tempDeliveryArea,
    deliveryarea,
  }) {
    const zoneId = Number(id)
    let updatedTempdDeliveryArea = [...tempDeliveryArea]
    const polygonZone = updatedTempdDeliveryArea.find(
      (zone) => zone.id === zoneId || zone.tempId === zoneId
    )
    if (polygonZone) {
      const newTempDeliveryArea = updatedTempdDeliveryArea.filter(
        (zone) => zone.id !== zoneId && zone.tempId !== zoneId
      )
      polygonZone.area.locations = locations.map((coord) => {
        return {
          latitude: latKeyName ? coord[latKeyName] : coord.lat,
          longitude: lngKeyName ? coord[lngKeyName] : coord.lng,
        }
      })
      if (name) { polygonZone.name = name }
      newTempDeliveryArea.push(polygonZone)
      updatedTempdDeliveryArea = newTempDeliveryArea
    } else {
      const polygonZoneFromState = deliveryarea.find(
        (zone) => zone.id === zoneId
      )
      if (polygonZoneFromState) {
        polygonZoneFromState.area.locations = locations.map((coord) => {
          return {
            latitude: latKeyName ? coord[latKeyName] : coord.lat,
            longitude: lngKeyName ? coord[lngKeyName] : coord.lng,
          }
        })
        if (name) { polygonZoneFromState.name = name }
        updatedTempdDeliveryArea.push(polygonZoneFromState)
      }
    }
    this.setState({ tempDeliveryArea: updatedTempdDeliveryArea })
  }

  fetchDeliveryAreaData(storeId) {
    const api = new API({ url: '/logistics-service/delivery-area' })
    const params = storeId && storeId !== 'ALL' ? { storeId } : {}
    api.get(params).then((response) => {
      const deliveryarea = response.data.deliveryarea || []
      this.setState({ deliveryarea })
    })
  }
  componentDidMount() {
    this.fetchDeliveryAreaData(this.state.storeId)
  }

  render() {
    return (
      <div>
        {this.state.kmlUploadPopupShown && (
          <Popup
            heading={getMessage('deliveryArea.kml.upload.text')}
            show={this.state.kmlUploadPopupShown}
            close={this.hideModal}
          >
            <KMLUpload
              storeId={this.state.storeId}
              successCallback={this.uploadSuccessCallback}
            />
          </Popup>
        )}
        <Dialog
          show={this.state.showError}
          close={() =>
            this.setState({ showError: false }, this.fetchDeliveryAreaData)
          }
          information={this.state.apiError}
          closeText={getMessage('extensions.error.dialog.okay')}
        />
        <Dialog
          show={this.state.showSelectStoreDialog}
          close={() => this.setState({ showSelectStoreDialog: false })}
          information={'select a store before uploading kml file'}
          closeText={getMessage('extensions.error.dialog.okay')}
        />
        <div className="flex-around">
          <h1>{getMessage('extensions.deliveryArea.title')}</h1>
          <AddDeliveryArea
            handleEditValuesOpen={this.handleEditValuesOpen}
            editing={this.state.editing}
            changeEditMode={this.changeEditMode}
            handleKmlUpload={this.showModal}
          />
        </div>
        <div className="delivery-area-content">
          <div className="map-area">
            <CommonMapComponent
              deliveryArea={this.state.tempDeliveryArea
                .concat(
                  this.state.deliveryarea.filter(
                    (zone) =>
                      !this.state.tempDeliveryArea.find(
                        (tempZone) => tempZone.id === zone.id
                      )
                  )
                )
                .filter((zone) => !zone.delete)}
              setNewLocation={this.setNewLocation}
              setNewLocations={this.setNewLocations}
              selectedLocation={this.state.selectedLocation}
              setNewEditedLocations={this.setNewEditedLocations}
              setEditPolygonId={this.setEditPolygonId}
              editPolygonId={this.state.editPolygonId}
              editing={this.state.editing}
              showEditButton={true}
              changeEditMode={this.changeEditMode}
              distinctColors={distinctColors}
              setEditId={this.setEditId}
              handlePincodeEdit={this.handlePincodeEdit}
              handleRadialZoneEdit={this.handleRadialZoneEdit}
            />
          </div>
          <div className="map-controls">
            <MapControls
              ref={this.mapControlsRef}
              parent={this}
              storeId={this.state.storeId}
              setStore={this.setStore}
              setNewLocation={this.setNewLocation}
              editing={this.state.editing}
              deliveryArea={this.state.tempDeliveryArea
                .concat(
                  this.state.deliveryarea.filter(
                    (zone) =>
                      !this.state.tempDeliveryArea.find(
                        (tempZone) => tempZone.id === zone.id
                      )
                  )
                )
                .filter((zone) => !zone.delete)}
              handleSubmit={this.handleSubmit}
              selectedLocation={this.state.selectedLocation}
              deleteSelectedLocation={this.deleteSelectedLocation}
              editPolygonId={this.state.editPolygonId}
              setEditPolygonId={this.setEditPolygonId}
              setPolygonName={this.setPolygonName}
              saveData={this.saveData}
              changeEditMode={this.changeEditMode}
              clearTempData={this.clearTempData}
              distinctColors={distinctColors}
              handleZoneDelete={this.handleZoneDelete}
              handlePincodeDelete={this.handlePincodeDelete}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default class DeliveryAreas extends Component {
  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
        className="delivery-areas"
      >
        <InterfaceContainer history={this.props.history} />
      </AuthenticatedPage>
    )
  }
}
