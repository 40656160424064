import { Input } from 'components/Form'
import { getMessage } from 'lib/translator'
import React from 'react'

const CardIDCategory = ({ method, id, category }) => {
  return (
    <div className="card-input-section">
      {method === 'edit' && (
        <Input
          type="text"
          label={getMessage('app.card.id')}
          placeholder={getMessage('app.card.id')}
          value={id}
          disabled={true}
        />
      )}
      <Input
        type="text"
        label={getMessage('app.card.category')}
        placeholder={getMessage('app.card.category')}
        value={category}
        disabled={true}
      />
    </div>
  )
}

export default CardIDCategory
