import React from 'react'
import { Input, Radio, BaseForm } from 'components/Form'
import { getMessage } from 'lib/translator'

const BANNER_STATUS_OPTIONS = [
  {
    value: 'Live',
    text: getMessage(`apphome.live`),
  },
  {
    value: true,
    text: getMessage(`apphome.enabled`),
  },
  {
    value: false,
    text: getMessage(`apphome.disabled`),
  },
]

const SPLASH_STATUS_OPTIONS = [
  {
    value: true,
    text: getMessage(`apphome.enabled`),
  },
  {
    value: false,
    text: getMessage(`apphome.disabled`),
  },
]

class Filters extends BaseForm {
  constructor(props) {
    super(props)
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const { type } = this.props.options

    return (
      <Form>
        <div className="form-fields">
          <Input
            id="name"
            label={getMessage('appbanner.searchByName')}
            placeholder={getMessage('appbanner.campaign.placeholder')}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            label={getMessage('appbanner.status')}
            options={
              type === 'banners' ? BANNER_STATUS_OPTIONS : SPLASH_STATUS_OPTIONS
            }
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true,
              defaultValue: 1,
            })}
          />
        </div>
        <SubmitButton testid="submit-filter">
          {getMessage('appbanner.submit')}
        </SubmitButton>
        <ClearButton testid="clear-filter">
          {getMessage('appbanner.clear')}
        </ClearButton>
      </Form>
    )
  }
}

export default Filters
