import React from 'react'
import { getMessage } from 'lib/translator'
import { ANALYTICS_PLACEHOLDER } from './utils'
import { Input, Textarea, VALIDATION_TYPES } from 'components/Form'

const ClickToAction = ({ generateStateMappers, errorField }) => {
  return (
    <>
      <h3 className="two-col">{getMessage('app.popup.cta.button')}</h3>
      <Input
        type="text"
        label={getMessage('app.popup.cta.text')}
        placeholder={getMessage('app.popup.cta.text.placeholder')}
        {...generateStateMappers({
          stateKeys: ['seeBtn', 'cta', 'text'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        type="text"
        label={getMessage('app.popup.cta.deeplink')}
        placeholder={getMessage('app.popup.cta.deeplink.placeholder')}
        {...generateStateMappers({
          stateKeys: ['seeBtn', 'cta', 'deeplink'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
      />
      <Textarea
        type="text"
        label={getMessage('app.popup.analytics')}
        name="cta-analytics"
        className={errorField.includes('seeBtn') && 'input-error'}
        placeholder={ANALYTICS_PLACEHOLDER}
        {...generateStateMappers({
          stateKeys: ['seeBtn', 'analytic'],
          loseEmphasisOnFill: true,
        })}
      />
    </>
  )
}

export default ClickToAction
