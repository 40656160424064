import React from 'react'
import { Link } from 'react-router-dom'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Cell, Row } from '../../../components/Table'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'

import DesignationFilter from './Filters'
import DesignationForm from './Form'
import EmptyIcon from './designation.svg'
import './style.css'

import API from 'lib/api'
import { getMessage } from '../../../lib/translator'
import { CloneDesignationForm } from './Form/CloneDesignation'
import { get } from 'lib/storage'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { When } from 'components/When'

export const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('designations.empty.message'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      {getMessage('designations.add')}
    </button>
  ),
}

const tableProperties = () => {
  return {
    headers: [
      getMessage('designations.header.name'),
      getMessage('designations.header.timingType'),
      getMessage('designations.header.manager'),
      getMessage('designations.header.actions'),
    ],
    row: ({ id, name, timingType, manager, onAction }) => (
      <Row>
        <Cell>
          <div
            className="designation-name"
            onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}
          >
            <span>{name}</span>
          </div>
        </Cell>
        <Cell>
          <div className="designation-timing-not-mobile">{timingType}</div>
          <div className="designation-timing">
            {
              <span className="text-muted">
                {getMessage('designations.header.timing')}
              </span>
            }
            <p>
              {timingType &&
                timingType.split('')[0] +
                  timingType
                    .toLowerCase()
                    .split('')
                    .slice(1, timingType.length)
                    .join('')}
            </p>
          </div>
        </Cell>
        <Cell>
          <div className="designation-manager">
            <span className="text-muted only-mobile">
              {getMessage('designations.header.manager')}
            </span>
            <p>{manager && manager.name}</p>
          </div>
        </Cell>
        <Cell className="designation-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id })
              }}
            >
              {getMessage('designations.edit')}
            </DropDownItem>
            <DropDownItem
              onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}
            >
              {getMessage('designations.delete')}
            </DropDownItem>
            <DropDownItem>
              <Link to={`/hr/employees?designationId=${id}`}>
                {getMessage('designations.view.employees')}
              </Link>
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

export default class Designations extends React.Component {
  state = {
    isCloneModalOpen: false,
    designations: [],
  }

  toggleModal = () => {
    this.setState((s) => ({ ...s, isCloneModalOpen: !s.isCloneModalOpen }))
  }
  componentDidMount() {
    this.designationApi = new API({ url: '/account-service/designation' })
    this.designationApi.get({ paginated: 'false' }).then((response) => {
      const designations = response.data.designation
      this.setState({
        designations,
      })
    })
  }

  render() {
    const flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
    const isCloneDesignationEnable =
      flags[SPLIT_FEATURES.CLONE_DESIGNATION]?.treatment === 'on'

    return (
      <ListingPage
        menu={this.props.menu}
        className="designations-page"
        title={getMessage('designations.title')}
        api={{
          url: '/account-service/designation',
          transform: (response) => response.data.designation,
        }}
        emptyState={EmptyState}
        tableProperties={tableProperties()}
        headerActions={({ onAction }) => (
          <>
            <When condition={isCloneDesignationEnable}>
              <CloneDesignationForm
                designations={this.state.designations}
                toggleModal={this.toggleModal}
                isOpen={this.state.isCloneModalOpen}
                refreshPage={() => onAction(TABLE_ACTIONS.REFRESH)}
              />
            </When>
            <button
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD)
              }}
            >
              {getMessage('designations.add')}
            </button>
          </>
        )}
        addHeading={getMessage('designations.addheader')}
        editHeading={getMessage('designations.editheader')}
        form={{
          component: DesignationForm,
          allowDelete: true,
          transformSubmit: formData => {
            const result = Object.assign({}, formData)
            if (formData.manager === '' || formData.manager === null) {
              result.managerId = ''
            } else if (formData.manager) {
              result.managerId = formData.manager.id
            }
            delete result.manager

            if (formData.roles) {
              const roleIds = formData.roles.map(role => role.id)
              result.roleIds = roleIds
              delete result.roles
            }
            return result
          },
        }}
        filters={{
          component: DesignationFilter,
          transformSubmit: formData => {
            const result = Object.assign({}, formData)
            if (formData.name) {
              result.name = formData.name.name
            }
            if (formData.manager) {
              result.managerId = formData.manager.id
              delete result.manager
            }
            return result
          },
        }}
      />
    )
  }
}
