import React from 'react'
import deleteIcon from '../delete.svg'
import { Input } from '../../Form'

import { getMessage } from '../../../lib/translator'
import { addReward, deleteReward, getTagDetails, getRcDetails } from '../utils'
import './style.css'
import moment from 'moment'
import Reward1 from './Reward1'
import PtrDateAndTimeFields from './PtrDateAndTimeFields'
import PtrOfferAndPtrRcFields from './PtrOfferAndPtrRcFields'
import RewardTypeSelect from './RewardTypeSelect'
import OfferFields from './OfferFields'

const AddReward = ({ _this, method, keys, taskIndex }) => {
  const { values } = _this.state
  const otherAwards = values?.taskList[taskIndex]?.awardList
  const isEditAndPastStart =
    method === 'edit' && moment().isAfter(values.startAt)

  const handleSearch = async (e, awardIndex, offerID) => {
    e.preventDefault()
    if (offerID) {
      const response = await getTagDetails(offerID)
      const {
        offerDetails,
        tagsOptions,
        offerError,
        promoCodeDescription,
        limitation,
        startAt,
        endAt,
      } = response

      const updatedValues = { ...values }
      updatedValues.taskList[taskIndex].awardList[awardIndex] = {
        ...updatedValues.taskList[taskIndex].awardList[awardIndex],
        offerDetails,
        tagIds: tagsOptions,
        tagId: tagsOptions?.[0]?.value || '',
        offerError,
        offerID: offerError ? '' : offerID,
        promoCodeDescription,
        limitation,
        startAt,
        endAt,
      }

      _this.setState({ values: updatedValues })
    }
  }

  const handleRCSearch = async (e, awardIndex, rcID) => {
    e.preventDefault()
    if (rcID) {
      const response = await getRcDetails(rcID)
      const { rcError, promoCodeDescription, limitation, startAt, endAt } =
        response

      const updatedValues = { ...values }
      updatedValues.taskList[taskIndex].awardList[awardIndex] = {
        ...updatedValues.taskList[taskIndex].awardList[awardIndex],
        rcError,
        rcID: rcError ? '' : rcID,
        promoCodeDescription,
        limitation,
        startAt,
        endAt,
      }

      _this.setState({ values: updatedValues })
    }
  }

  return (
    <div className="task-section mt">
      <Reward1 _this={_this} method={method} keys={keys} />

      {otherAwards?.map((award, awardIndex) => {
        const {
          type,
          offerDetails = '',
          offerID = '',
          rcID = '',
          title = '',
          description = '',
          tagIds = [],
          offerError = '',
          rcError = '',
          limitation = '',
        } = award
        return (
          <div className="mb-1" key={awardIndex}>
            <div className="task-popup">
              <div className="task-header reward-header">
                <div
                  className="header-1-lowercase header-rewards"
                  data-testid={`reward ${awardIndex + 2}`}
                >
                  {getMessage('omnichallenge.task.rewardHeader')}{' '}
                  {awardIndex + 2}
                </div>

                {otherAwards.length !== 0 && (
                  <button
                    className="pb05"
                    type="button"
                    data-testid={`del-reward-btn-${awardIndex + 1}`}
                    onClick={() => deleteReward(_this, taskIndex, awardIndex)}
                    disabled={isEditAndPastStart}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                )}
              </div>
              <div className="row mt mb">
                <RewardTypeSelect
                  _this={_this}
                  taskIndex={taskIndex}
                  awardIndex={awardIndex}
                />
                {type === 'PTR_RC' && (
                  <>
                    <div className="col-6">
                      <Input
                        name={`select-rcid-${awardIndex}`}
                        label={getMessage(
                          'omnichallenge.task.rewardsCatalogueID'
                        )}
                        placeholder={getMessage(
                          'omnichallenge.task.rewardsCatalogueID'
                        )}
                        type="text"
                        value={rcID}
                        {..._this.generateStateMappers({
                          stateKeys: [
                            'taskList',
                            taskIndex,
                            'awardList',
                            awardIndex,
                            'rcID',
                          ],
                        })}
                        secondaryAction={{
                          name: (
                            <img
                              key="search"
                              src="/static/media/icon-search.462fc200.svg"
                              alt={`select-rcid-${awardIndex}`}
                            />
                          ),
                          onAction: (e) => {
                            handleRCSearch(e, awardIndex, rcID)
                          },
                        }}
                      />
                      {rcError && <div className="error">{rcError}</div>}
                    </div>
                  </>
                )}
                {type === 'PTR_BENEFIT' && (
                  <div className="col-6">
                    <Input
                      label={getMessage('rewards.title')}
                      type="text"
                      value={title}
                      {..._this.generateStateMappers({
                        stateKeys: [
                          'taskList',
                          taskIndex,
                          'awardList',
                          awardIndex,
                          'title',
                        ],
                      })}
                    />
                  </div>
                )}
                {type === 'OFFER' || type === 'PTR_OFFER' ? (
                  <OfferFields
                    _this={_this}
                    taskIndex={taskIndex}
                    awardIndex={awardIndex}
                    offerDetails={offerDetails}
                    offerID={offerID}
                    tagIds={tagIds}
                    offerError={offerError}
                    handleSearch={handleSearch}
                    type={type}
                  />
                ) : (
                  type !== 'PTR_OFFER' &&
                  type !== 'PTR_RC' && (
                    <div className="col-6">
                      <Input
                        label={getMessage('omnichallenge.description')}
                        type="text"
                        value={description}
                        {..._this.generateStateMappers({
                          stateKeys: [
                            'taskList',
                            taskIndex,
                            'awardList',
                            awardIndex,
                            'description',
                          ],
                        })}
                      />
                    </div>
                  )
                )}

                {type === 'PTR_BENEFIT' && (
                  <div className="col-6">
                    <Input
                      label={getMessage(
                        'omnichallenge.task.redemptionLimitation'
                      )}
                      type="text"
                      value={limitation}
                      {..._this.generateStateMappers({
                        stateKeys: [
                          'taskList',
                          taskIndex,
                          'awardList',
                          awardIndex,
                          'limitation',
                        ],
                      })}
                    />
                  </div>
                )}
              </div>
              {type === 'PTR_BENEFIT' && (
                <PtrDateAndTimeFields
                  _this={_this}
                  taskIndex={taskIndex}
                  awardIndex={awardIndex}
                />
              )}
              {(type === 'PTR_RC' || type === 'PTR_OFFER') && (
                <PtrOfferAndPtrRcFields
                  _this={_this}
                  taskIndex={taskIndex}
                  awardIndex={awardIndex}
                />
              )}
            </div>
          </div>
        )
      })}
      <div className="flex mt-1 mb-1">
        <button
          data-testid="add-reward-btn"
          className="button mr-3"
          type="button"
          onClick={() => addReward(_this, taskIndex)}
          disabled={isEditAndPastStart}
        >
          + {getMessage('omnichallenge.addReward')}
        </button>
      </div>
    </div>
  )
}

export default AddReward
