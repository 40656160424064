import React, { Component } from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import Loader from 'components/Loader'
import Tabs from 'components/Tabs'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import InfoCard from 'components/InfoCard'
import PhoneAndEmailDisplay from 'components/PhoneAndEmailCard'
import AddressCard from 'pages/operations/Customers/Details/AddressCard'
import { getNewDisplayAddress } from 'lib/commonlyused'
import CustomerDetailsCard from 'components/CustomerDetailsCard'

import { Provider } from 'containers/Context'
import NewListingPage from 'containers/NewListingPage'
import { getOnlyDate } from 'lib/datetime'
import { GIFTING_PAGINATION_SIZE } from 'pages/customer-support/constants'
import tablePropertie, {
  transform,
} from 'pages/customer-support/EGiftingOrders/List/tableProperties'

import OrdersImage from './total-orders.svg'
import PurchasedImage from './purchase-worth.svg'
import JoinedImage from './joined-on.svg'
import LastPurchaseImage from './last-purchase.svg'
import './style.css'

const EmptyState = {
  message: getMessage('customerSupport.egifting.empty'),
}

const addressLocalisation = 'customer.details.pages.address'

export default class Details extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      data: null,
      entities: null,
      ordersData: null,
      loading: false,
      failed: false,
      invoiceShown: false,
    }

    this.pages = [
      getMessage('customer.details.pages.basicinfo'),
      getMessage(addressLocalisation),
      getMessage('customer.details.pages.phoneemail'),
    ]
  }

  getPhoneNumbers() {
    const phone =
      this.state.entities?.customers?.map((customer) => customer.phone) || []
    return (
      phone.length > 0 &&
      phone.map((item, i) => (
        <PhoneAndEmailDisplay value={item} type="phone" key={i} />
      ))
    )
  }

  getEmails() {
    const email =
      this.state.entities?.customers?.map((customer) => customer.email) || []
    return (
      email.length > 0 &&
      email.map((mail, i) => (
        <PhoneAndEmailDisplay key={i} value={mail} type="email" />
      ))
    )
  }

  getAddresses() {
    const customerId = this.state.entities?.id
    const customerAddresses = this.state.entities?.address || {}
    customerAddresses.floorNumber = customerAddresses.floor
    return (
      <AddressCard
        customerId={customerId}
        title={getMessage(addressLocalisation)}
        value={`${getNewDisplayAddress(customerAddresses)}`}
      />
    )
  }

  fetchEntityData() {
    const page = this.pages[this.state.page]
    if (page === getMessage('customer.details.pages.basicinfo')) {
      return (
        <div className="tab-details">
          <div className="tab-row">
            <InfoCard
              className="total-orders"
              src={OrdersImage}
              title={getMessage('customer.details.pages.basicinfo.totalorders')}
              description={this.state.ordersData?.totalSize}
            />
            <InfoCard
              className="total-amount"
              src={PurchasedImage}
              description={this.state.entities?.totalAmountSpent || 0}
              title={getMessage(
                'customer.details.pages.basicinfo.purchasesworth'
              )}
            />
          </div>
          <div className="tab-row">
            <InfoCard
              className="joined-on"
              src={JoinedImage}
              title={getMessage('customer.details.pages.basicinfo.joined')}
              description={getOnlyDate(this.state?.entities?.createTime)}
            />
            <InfoCard
              className="last-purchase"
              src={LastPurchaseImage}
              title={getMessage(
                'customer.details.pages.basicinfo.lastPurchase'
              )}
              description={getOnlyDate(
                this.state?.entities?.lastOrder?.createTime
              )}
            />
          </div>
        </div>
      )
    }
    if (page === getMessage(addressLocalisation)) {
      return (
        <div className="tab-details">
          {this.getAddresses()}
          <div className="addAddress" onClick={this.addAddressCallback}>
            <div className="addIcon" />
          </div>
        </div>
      )
    }
    if (page === getMessage('customer.details.pages.phoneemail')) {
      return (
        <div className="tab-details">
          <div className="phone-email">
            <div className="phone">{this.getPhoneNumbers()}</div>
            <div className="email">{this.getEmails()}</div>
          </div>
        </div>
      )
    }
    return <></>
  }

  componentDidMount() {
    const { id = '' } = this.props?.router?.match?.params
    this.getOrder = new API({
      url: `/gifting-admin-service/orders?entity_id=${id}`,
    })

    this.getEntity = new API({
      url: `/gifting-admin-service/entities/${id}`,
    })

    Promise.all([this.getOrder.get(), this.getEntity.get()])
      .then(([orderResponse, { data }]) => {
        this.setState({
          entities: data,
          ordersData: orderResponse?.data,
        })
      })
      .catch((error) => {
        console.log(error, 'Error')
      })

    this.communicationApi = new API({
      url: `/account-service/config/communication`,
    })
    this.communicationApi
      .get()
      .then((response) => {
        this.setState({ communicationConfig: response.data.config })
      })
      .catch((error) => {
        throw error
      })
  }

  render() {
    return (
      <AuthenticatedPage
        className="evoucher-entities"
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1 className="heading">
          {this.state.entities && this.state.entities.name
            ? this.state.entities.name
            : ''}
        </h1>
        {this.state.loading ? (
          <Loader />
        ) : (
          <>
            <div className="tabWithCustomerCard">
              <div className="tab-container">
                <Tabs
                  items={this.pages.map((page) => `${page}`)}
                  default={this.state.page}
                  data-testid="details-btn"
                  onClick={(index) => {
                    this.setState({ page: index })
                  }}
                />
                {this.fetchEntityData()}
              </div>
              <CustomerDetailsCard
                uen={this.state.entities?.uen}
                name={this.state.entities?.name}
                editHandler={() =>
                  this.props.history.push(
                    `/customer-support/gifting-entities/edit/${this.state.entities?.id}`
                  )
                }
                isSaved={this.props.history?.location?.state?.isEdit}
                isEntity
              />
            </div>
            <h3>{getMessage('customer.details.order.purchase.history')}</h3>
            <div className="tab-details">
              <Provider value={this.state && this.state.communicationConfig}>
                <div className="tab-details">
                  <div className="orders-page">
                    <NewListingPage
                      className="entities-details-orders-page"
                      api={{
                        url: `/gifting-admin-service/orders`,
                        params: {
                          ...this.props.params,
                          page_size: GIFTING_PAGINATION_SIZE,
                          entity_id: this.props.router?.match?.params?.id,
                        },
                        isPageDisabled: true,
                        transform,
                      }}
                      emptyState={EmptyState}
                      primaryKey="id"
                      tableProperties={tablePropertie()}
                      tableDynamic
                    />
                  </div>
                </div>
              </Provider>
            </div>
          </>
        )}
      </AuthenticatedPage>
    )
  }
}
