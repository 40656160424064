import React, { useState, useEffect } from 'react'
import CardCategoryForm from 'components/AppHome/AppCard/CardCategoryForm'
import ListingPage, { TABLE_ACTIONS } from 'containers/ListingPage'
import { tableProperties } from 'components/AppHome/AppCard/utils'
import PreviewCard from 'components/AppHome/AppCard/PreviewCard'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ManageGenericSwimlaneForm from './Form'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import Loader from 'components/Loader'
import API from 'lib/api'
import './style.css'

const Actions = ({ setIsPreview, onAction }) => {
  return (
    <div className="actions-section">
      <button
        className="primary button"
        onClick={() => {
          setIsPreview(true)
        }}
      >
        <span className="text">{getMessage('app.card.preview.all')}</span>
      </button>
      <button
        className="primary button"
        onClick={() => {
          onAction(TABLE_ACTIONS.ADD)
        }}
      >
        <span className="text">{getMessage('app.card.create')}</span>
      </button>
    </div>
  )
}

const ManageCard = (props) => {
  const [categorySelected, setCategorySelected] = useState(null)
  const [previewCards, setPreviewCards] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [isSorted, setIsSorted] = useState(false)

  const moveCard = (dragIndex, hoverIndex) => {
    setPreviewCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }).reduce((accumulator, item, index) => {
        accumulator[index] = {
          ...item,
          priority: prevCards[index].priority,
        }
        return accumulator
      }, [])
    )
    setIsSorted(true)
  }

  const closePopup = () => {
    setCategorySelected(null)
    setIsPreview(false)
    setIsSorted(false)
  }

  useEffect(() => {
    if (categorySelected) {
      new API({
        url: `/genie/lms/cards/preview?category=${categorySelected}`,
      })
        .get()
        .then((res) => {
          setPreviewCards(res)
          setisLoading(false)
        })
    }
  }, [categorySelected])

  return (
    <DndProvider backend={HTML5Backend}>
      <ListingPage
        testid="appcard-listingpage"
        className="appcard-listingpage"
        title="Manage Card"
        addHeading="Create Card"
        editHeading="Edit Card"
        menu={props.menu}
        api={{
          url: `/genie/lms/cards`,
          transform: (res) => {
            if (res?.items.length > 0) {
              return res.items
            }
            return null
          },
        }}
        headerActions={({ onAction }) => (
          <Actions setIsPreview={setIsPreview} onAction={onAction} />
        )}
        form={{
          component: ManageGenericSwimlaneForm,
        }}
        tableProperties={tableProperties()}
        viewHeading="Card Details"
        modalClassName="appcard-popup"
        tableDynamic
      />
      <Popup
        show={isPreview}
        heading={getMessage('app.card.preview.all')}
        close={closePopup}
        className="previewcard-popup"
      >
        {!categorySelected && (
          <CardCategoryForm
            onSelected={(val) => {
              setisLoading(true)
              setCategorySelected(val)
            }}
          />
        )}
        {categorySelected &&
          (isLoading ? (
            <Loader size="sm" />
          ) : (
            <PreviewCard
              isSorted={isSorted}
              closePopup={closePopup}
              previewCards={previewCards}
              moveCard={moveCard}
            />
          ))}
      </Popup>
    </DndProvider>
  )
}

export default ManageCard
