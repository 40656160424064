import React from 'react'
import PopupForm from './Form'
import { tableProperties, linkButtons } from 'components/AppHome/AppPopup/utils'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import './style.css'

const ManagePopup = (props) => {
  return (
    <ListingPageWithRoutes
      title="Manage Popup"
      addHeading="Add Popup"
      editHeading="Edit Popup"
      menu={props.menu}
      className="app-popup-listingpage"
      api={{
        url: `/genie/lms/content`,
        transform: (res) => res.data.items,
      }}
      headerActions={linkButtons}
      emptyState={{ actions: linkButtons }}
      form={{
        component: PopupForm,
      }}
      tableProperties={tableProperties()}
      viewHeading="Popup Details"
      modalClassName="resend-popup"
      primaryKey="sk"
      tableDynamic
    />
  )
}

export default ManagePopup
