import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import './style.css'

import Visa from 'icons/VISA.png'
import Amex from 'icons/AMEX@2x.png'
import MasterCard from 'icons/Mastercard.png'

const getPaymentLogo = (paymentMode) => {
  let icon
  const lowercasePaymentMode = paymentMode
    ? paymentMode.toLocaleLowerCase()
    : ''
  switch (lowercasePaymentMode) {
    case 'visa':
      icon = Visa
      break
    case 'master':
    case 'mastercard':
      icon = MasterCard
      break
    case 'amex':
    case 'americanexpress':
      icon = Amex
      break
    default:
      icon = Visa
  }
  return icon
}

const InvoiceBody = ({ invoiceDetails }) => {
  const isB2bEnabled = invoiceDetails?.isB2B === 1
  return (
    <>
      <div className="body-container">
        <div className="tax-invoice">Tax Invoice</div>
        <div className="header-subtitle">
          {moment(invoiceDetails?.createTime).format('MM/DD/YYYY HH:mm')}
        </div>
        {isB2bEnabled && (
          <>
            <div className="titleM margin-top-32">Billed to</div>
            <div>
              <div className="bodyM">{invoiceDetails.sender || ''}</div>
            </div>
            <div>
              <div className="bodyM">{invoiceDetails.entity?.name || ''}</div>
            </div>
            <div>
              <div className="bodyM">
                {`${invoiceDetails?.entity?.address?.address || ''} ${
                  invoiceDetails?.entity?.address?.buildingName || ''
                } ${invoiceDetails?.entity?.address?.floor || ''}-${
                  invoiceDetails?.entity?.address?.unitNumber || ''
                } ${invoiceDetails?.entity?.address?.postalCode || ''}`}
              </div>
            </div>
          </>
        )}
        <div className="header-subtitle margin-top-32">
          Total{' '}
          <div className="total-label">
            $
            {Number(invoiceDetails?.amount)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
        <div className="header-subtitle">
          Tax (0% GST) <div className="total-label">$0.00</div>
        </div>
        <div className="horizontal-line" />
        <div className="header-subtitle titleM">
          Total (incl. tax)
          <div className="total-label">
            $
            {Number(invoiceDetails?.amount)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
        <div className="header-subtitle titleM">
          Paid by{' '}
          <div className="total-label">
            <img
              alt="card-type"
              className="payment-logo"
              src={getPaymentLogo(invoiceDetails?.methods?.card?.scheme)}
            />
            {invoiceDetails?.methods?.card?.number.substr(-4)}
          </div>
        </div>
        <div className="dashed-hr" />
      </div>
      <div className="body-invoice-content">
        <div className="titleM">Order Details</div>
        <div className="bodyM margin-bottom-32">
          Order number {invoiceDetails?.reference}
        </div>
        {isB2bEnabled
          ? invoiceDetails.extendedGifts?.map((invoice, index) => (
              <>
                <div className="titleS margin-top-32">
                  Order {index + 1} ({invoice.metadata?.name})
                </div>
                <div className="titleXs margin-top-16">
                  Delivery information
                </div>
                <div className="row margin-top-12">
                  <div className="col-12">
                    <div className="bodyM">Send order to:</div>
                  </div>
                  <div className="col-12">
                    <div className="bodyM">Send password to:</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="bodyM">{invoice.addressee?.voucher}</div>
                  </div>
                  <div className="col-12">
                    <div className="bodyM">{invoice.addressee?.password}</div>
                  </div>
                </div>
                <div className="row margin-top-16">
                  <div className="col-12">
                    <div className="bodyM">Amount</div>
                  </div>
                  <div className="col-12">
                    <div className="bodyM">Quantity</div>
                  </div>
                  <div className="col-12">
                    <div className="bodyM">Subtotal</div>
                  </div>
                </div>
                <div className="horizontal-line" />

                {invoice.denominations.map((denomination, denIndex) => (
                  <div className="row" key={denIndex}>
                    <div className="col-12">
                      <div className="bodyM">
                        $
                        {Math.round(denomination.amount).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="bodyM">
                        {denomination.quantity.toLocaleString()}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="bodyM">
                        $
                        {(
                          denomination.amount * denomination.quantity
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ))
          : invoiceDetails?.extendedGifts?.map((item) => (
              <>
                <div className="row margin-top-16">
                  <div className="col-12">
                    <div className="bodyM">Denomination</div>
                  </div>
                  <div className="col-12">
                    <div className="bodyM">Quantity</div>
                  </div>
                  <div className="col-12">
                    <div className="bodyM">Subtotal</div>
                  </div>
                </div>
                <div className="horizontal-line" />
                <div className="row">
                  <div className="col-12">
                    <div className="header-subtitle">
                      {Object.keys(
                        _(item.receivers)
                          .groupBy((x) => x.amount)
                          .value()
                      ).map((y) => y)}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="header-subtitle">
                      {item.quantity.toLocaleString()}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="header-subtitle">
                      $
                      {Object.keys(
                        _(item.receivers)
                          .groupBy((x) => x.amount)
                          .value()
                      )
                        .map((x) => Number(x) * Number(item.quantity))
                        .toLocaleString()}
                    </div>
                  </div>
                </div>
              </>
            ))}

        <div className="row container-padding">
          <div className="font-size-label col-12">
            <div className="bodyM">Note:</div>
            <div className="font-size-label margin-top-small col-12">
              <div className="bodyM">
                For more information, please visit our{' '}
                <a href="/">Help Centre</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceBody
