import { getMessage } from 'lib/translator'
import React from 'react'
import { CATEGORIES } from './utils'

export const CardCategoryForm = ({ onSelected }) => {
  return (
    <div className="appcard-category-form">
      {CATEGORIES.map(({ category, text }, index) => (
        <button
          className="button appcard-hover-button"
          onClick={() => onSelected(category)}
          type="button"
          key={index}
          disabled={category === 'SWA'}
        >
          {getMessage(text)}
        </button>
      ))}
    </div>
  )
}

export default CardCategoryForm
