import React from 'react'
import BannerUploadForm from './Form'
import {
  linkButtons,
  tableProperties,
} from 'components/AppHome/AppBanner/utils'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import Filters from 'components/AppHome/Filters'
import './style.css'

const BannerUpload = (props) => {
  return (
    <ListingPageWithRoutes
      testid="appbanner-listing"
      title="Manage Banners"
      addHeading="Add Banner"
      editHeading="Edit Banner"
      menu={props.menu}
      className="appbanner-listingpage"
      api={{
        url: `/genie/lms/banners`,
        transform: (res) => res.items,
      }}
      headerActions={linkButtons}
      emptyState={{ actions: linkButtons }}
      form={{
        component: BannerUploadForm,
      }}
      tableProperties={tableProperties()}
      viewHeading="Banner Details"
      modalClassName="resend-popup"
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'banners',
        },
      }}
      tableDynamic
    />
  )
}

export default BannerUpload
