import React from 'react'
import { TABLE_ACTIONS } from 'containers/ListingPage'
import { ColumnDate, ColumnStatus, TableAction, getStatus } from '../utils'
import { getMessage } from 'lib/translator'
import { Row, Cell } from '../../Table'
import Card from './Card'

const TEXTS = {
  SWA: ['title', 'description'],
  ContentHub: ['description'],
}

const VARIANT = {
  ContentHub: 'CONTENT',
  SWA: 'NORMAL',
}

const CATEGORIES = [
  {
    category: 'ContentHub',
    text: 'app.card.type.contenthub',
  },
  {
    category: 'SWA',
    text: 'app.card.type.swa',
  },
]

const tableProperties = () => {
  return {
    headers: [
      'Card',
      'Name',
      'Category',
      'Start Date',
      'End Date',
      'Status',
      'Actions',
    ],
    row: ({
      id,
      name,
      image,
      texts,
      ComponentSK,
      startTime,
      endTime,
      isEnable,
      onAction,
    }) => {
      const status = isEnable
        ? getStatus(startTime, endTime)
        : getMessage('apphome.disabled')
      const category = ComponentSK.split(':')[0]
      return (
        <Row>
          <Cell className="column-card">
            <Card category={category} src={image} texts={texts} />
          </Cell>
          <Cell className="column-name">
            <span
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id })
              }}
            >
              {name}
            </span>
          </Cell>
          <Cell className="column-category">{category}</Cell>
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableAction
              id={id}
              onAction={onAction}
              isEnable={isEnable}
              type="cards"
            />
          </Cell>
        </Row>
      )
    },
  }
}

export { TEXTS, CATEGORIES, VARIANT, tableProperties }
