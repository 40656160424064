import React from 'react'
import { BaseForm, Checkbox, Input } from '../../../../../components/Form'

import { formatTime } from '../../../../../lib/datetime'
import { getMessage } from '../../../../../lib/translator'

const getCutOffMins = (startTime, cutOff) => {
  if (!cutOff || !startTime) {
    return null
  }
  const timeParts = startTime.split(':')
  const cutOffParts = cutOff.split(':')
  const difference = Math.abs(
    new Date(2018, 10, 20, timeParts[0], timeParts[1], timeParts[2]) -
      new Date(2018, 10, 20, cutOffParts[0], cutOffParts[1], cutOffParts[2])
  )
  return Math.ceil(difference / 1000 / 60)
}

class StoreSlotForm extends BaseForm {
  constructor(props) {
    super(props)
    const cutOff =
      props.value &&
      getCutOffMins(props.value.slot.startTime, props.value.cutOff)
    this.state.values.cutOff = cutOff
  }
  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    const { values } = this.state
    const { slot } = { ...values }
    return (
      <div className="store-slot-form">
        <Form>
          {this.state.formError && <div>{this.state.formError}</div>}
          <label className="thin">
            {getMessage('slots.timing').toUpperCase()}
          </label>
          <h5>
            {slot.startTime
              ? `${formatTime(slot.startTime)} - ${formatTime(slot.endTime)}`
              : `${getMessage('slots.asap')} ${slot.endTime.split(':')[2]} ${getMessage('slots.asap.minute')}`
            }
          </h5>
          <div className="form-fields">
            {!this.getState(['permanentBlock']) &&
              (this.state.values.slot.type !== 'ASAP') && (
                <Input
                  type="number"
                  min="0"
                  name="cutOff"
                  label={getMessage('slots.cutoff.mins')}
                  placeholder={getMessage('slots.cutoff.placeholder')}
                  {...this.generateStateMappers({
                    stateKeys: ['cutOff'],
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
            <Checkbox
              label={getMessage('slots.status')}
              inlineLabel={getMessage('slots.disable')}
              name="status"
              {...this.generateStateMappers({
                stateKeys: ['permanentBlock'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="form-actions">
            <SubmitButton>{getMessage('slots.save')}</SubmitButton>
            <CancelButton>{getMessage('slots.cancel')}</CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}

export default StoreSlotForm

export { getCutOffMins }
