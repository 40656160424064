import React, { Component } from 'react'
import StoreSelector, {
  makestoreDependentComponent,
  getDefaultStore,
} from '../../../../containers/StoreSelector'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import EmptyState from '../../../../components/EmptyState'
import './style.scss'

import EmptyIcon from '../EditSlots/empty.svg' // TODO: Change this
import { getMessage } from '../../../../lib/translator'
import { formatTime, getMinutes } from '../../../../lib/datetime'
import { BaseForm, Checkbox, Select } from '../../../../components/Form'
import { Dialog } from '../../../../components/Popup'
import moment from 'moment'
import API from '../../../../lib/api'
import { getSession } from 'lib/auth';
import { getExtensionDetails, isExtensionEnabled } from '../../../../lib/auth'
import Loader from '../../../../components/Loader'
import { getCutOffMins } from '../StoreSlotConfigure/Form'

const todaySlotText = 'slots.today';
const tomorrowSlotText = 'slots.tomorrow';
const slotDateFormat = 'ddd, MMM Do';
const dateFormat = 'YYYY-MM-DD'

export class InterfaceContainer extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      values: { DELIVERY: [], PICKUP: [], RB_PREORDER: [] },
      prevValues: { DELIVERY: [], PICKUP: [], RB_PREORDER: [] },
      deliverySupported: false,
      pickupSupported: false,
      preOrderSupported: false,
      selectedOption: 'DELIVERY',
      slots: []
    }
    this.options = [];
    this.changeStore = this.changeStore.bind(this)
    this.checkIfValueChanged = this.checkIfValueChanged.bind(this)
    this.findIndex = this.findIndex.bind(this)
    this.fetchSlotDetails = this.fetchSlotDetails.bind(this)
    this.handleOrderSelect = this.handleOrderSelect.bind(this)
  }

  handleOrderSelect(val) {
    this.setState({
      selectedOption: val,
    })
  }

  changeStore(storeId) {
    this.setState({ storeId }, this.fetchSlotDetails)
  }

  convertDataToMapState(data) {
    return Object.keys(data).map(date => {
      return data[date].map(slot => {
        slot.date = date
        slot.status = slot.status === 'ENABLED'
        return slot
      })
    })
  }

  async fetchSlotsForDelivery(storeId, duration) {
    this.deliverySlotsApi = new API({
      url: `/order-service/store/${storeId}/slot`,
    })
    const slotApiparams = {
      startDate: moment().format(dateFormat),
      endDate: moment(new Date())
        .add(duration)
        .format(dateFormat),
      orderType: 'DELIVERY'
    }
    await this.deliverySlotsApi
      .get(slotApiparams)
      .then(response => {
        const modifiedResponse = this.convertDataToMapState(response.data)
        const newvalues = JSON.parse(JSON.stringify(this.state.values))
        newvalues['DELIVERY'] = JSON.parse(
          JSON.stringify(modifiedResponse)
        )
        this.setState(prevState => {
          return {
            values: newvalues,
            dates: Object.keys(response.data),
            slots: {
              ...prevState.slots,
              DELIVERY: Object.keys(response.data).length
                ? response.data[Object.keys(response.data)[0]]
                : []
            },
            loading: false,
            prevValues: {
              ...prevState.prevValues,
              DELIVERY: JSON.parse(JSON.stringify(modifiedResponse)),
            },
          }
        })
      })
      .catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
      })
  }

  async fetchSlotsForPickup(storeId, duration) {
    this.pickupSlotsApi = new API({
      url: `/order-service/store/${storeId}/slot`,
    })
    const pickupApiParams = {
      startDate: moment().format(dateFormat),
      endDate: moment(new Date())
        .add(duration)
        .format(dateFormat),
      orderType: 'PICKUP',
    }
    await this.pickupSlotsApi
      .get(pickupApiParams)
      .then(response => {
        const modifiedResponse = this.convertDataToMapState(response.data)
        const newvalues = JSON.parse(JSON.stringify(this.state.values))
        newvalues['PICKUP'] = JSON.parse(JSON.stringify(modifiedResponse))
        this.setState(prevState => {
          return {
            dates: Object.keys(response.data),
            values: newvalues,
            slots: {
              ...prevState.slots,
              PICKUP: Object.keys(response.data).length ? response.data[Object.keys(response.data)[0]] : []
            },
            loading: false,
            prevValues: {
              ...prevState.prevValues,
              PICKUP: JSON.parse(JSON.stringify(modifiedResponse)),
            },
          }
        })
      })
      .catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
      })
  }

  fetchSlotsForPreOrder(storeId, duration) {
    this.preOrderSlotsApi = new API({
      url: `/order-service/store/${storeId}/slot`
    })
    const preOrderApiParams = {
      startDate: moment().format(dateFormat),
      endDate: moment(new Date()).add(duration).format(dateFormat),
      orderType: 'RB_PREORDER',
    }
    this.preOrderSlotsApi
      .get(preOrderApiParams)
      .then(response => {
        const modifiedResponse = this.convertDataToMapState(response.data)
        const newvalues = JSON.parse(JSON.stringify(this.state.values))
        newvalues['RB_PREORDER'] = JSON.parse(JSON.stringify(modifiedResponse))
        this.setState(prevState => {
          return {
            loading: false,
            values: newvalues,
            dates: Object.keys(response.data),
            slots: {
              ...prevState.slots,
              RB_PREORDER: Object.keys(response.data).length ? response.data[Object.keys(response.data)[0]] : []
            },
            prevValues: {
              ...prevState.prevValues,
              RB_PREORDER: JSON.parse(JSON.stringify(modifiedResponse)),
            },
          }
        })
      })
      .catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
      })
  }

  fetchSlotDetails(data) {
    const { deliverySupported, pickupSupported, preOrderSupported } = data ? data : this.state;
    this.setState({ loading: true })
    const storeId = getDefaultStore(this.props.stores).storeId
    this.visibleDaysApi = new API({
      url: `/account-service/extension/${
        getExtensionDetails('DeliverySlots').id
      }`,
    })
    this.visibleDaysApi
      .get()
      .then(async response => {
        const visibleDays = response.data?.extension.config?.globalConfig.visibleDaysForSlot
        const duration = moment.duration({ days: visibleDays - 1 })

        if (preOrderSupported) {
          await this.fetchSlotsForPreOrder(storeId, duration);
        }
        if (deliverySupported) {
          await this.fetchSlotsForDelivery(storeId, duration);
        }

        if (pickupSupported) {
          await this.fetchSlotsForPickup(storeId, duration);
        }

      })
      .catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        } else if (error.message === 'cancelled') {
          return
        }
        this.setState({
          error: error.message,
          loading: false,
        })
      })
  }

  componentWillUnmount() {
    this.visibleDaysApi && this.visibleDaysApi.cancel()
    this.deliverySlotsApi && this.deliverySlotsApi.cancel()
    this.pickupSlotsApi && this.pickupSlotsApi.cancel()
    this.preOrderSlotsApi && this.preOrderSlotsApi.cancel()
  }

  transformResponse(response) {
    const modifiedRule = response.data.storeslotrule
    modifiedRule.cutOff = getCutOffMins(
      modifiedRule.slot.startTime,
      modifiedRule.cutOff
    )
    modifiedRule.slotName =
      modifiedRule.slot.type === 'STANDARD'
        ? `${formatTime(modifiedRule.slot.startTime)} - ${formatTime(
            modifiedRule.slot.endTime
          )}`
        : `${getMessage('slots.asap')} ${
            modifiedRule.slot.endTime.split(':')[2]
          } ${getMessage('slots.asap.minute')}`
    return modifiedRule
  }

  findIndex(item) {
    const id = item.data.storeslotrule.slot.id
    return this.state.values.findIndex(value => value.slot.id === id)
  }

  checkIfValueChanged(data, type) {
    const changedSlots = []
    data.forEach((date, dateIndex) => {
      date.forEach((slot, slotIndex) => {
        if (
          slot.status !==
          this.state.prevValues[type][dateIndex][slotIndex].status
        ) {
          changedSlots.push(slot)
        }
      })
    })
    return changedSlots
  }

  onSubmit(formData) {
    const defaultSelected =
      (this.options && this.options.length > 0 && this.options[0].value) || null
    const val = this.state.selectedOption || defaultSelected
    this.setState({ submitting: true })
    const data = JSON.parse(JSON.stringify(formData))
    let changedRules = this.checkIfValueChanged(
      JSON.parse(JSON.stringify(data[val])),
      val
    )
    const storeId = getDefaultStore(this.props.stores).storeId
    changedRules = changedRules.map(rule => {
      rule.status = rule.status ? 'ENABLED' : 'DISABLED'
      rule.slotId = rule.id || rule.slotId
      delete rule.endTime
      delete rule.startTime
      delete rule.type
      delete rule.id
      return rule
    })
    /* Grouping by slotId */
    const modifiedChangedRulesObj = {}
    changedRules.forEach(rule => {
      if (rule.slotId in modifiedChangedRulesObj) {
        modifiedChangedRulesObj[rule.slotId][rule.date] = rule.status
      } else {
        modifiedChangedRulesObj[rule.slotId] = {}
        modifiedChangedRulesObj[rule.slotId][rule.date] = rule.status
      }
    })
    const modifiedChangedRules = Object.keys(modifiedChangedRulesObj).map(
      slotId => {
        return {
          slotId,
          slotDetails: modifiedChangedRulesObj[slotId],
        }
      }
    )

    if (modifiedChangedRules.length) {
      Promise.all(
        modifiedChangedRules.map(params => {
          const api = new API({ url: `/order-service/store/${storeId}/slot` })
          return api.put(params)
        })
      )
        .then(() => {
          this.setState(prevState => {
            return {
              submitting: false,
              successMessage: true,
              values: { ...prevState.values, [val]: data[val] },
              prevValues: {
                ...prevState.prevValues,
                [val]: JSON.parse(JSON.stringify(data[val])),
              },
              formError: null,
            }
          })
        })
        .catch(error => {
          this.setState({
            submitting: false,
            successMessage: false,
            formError: error.message,
          })
          if (error.code === 401 || error.code === 403) {
            throw error
          }
        })
    } else {
      this.setState({
        submitting: false,
        successMessage: true,
        formError: null,
      })
    }
  }

  async componentDidMount() {
    this.setState({ showSelector: true })
    this.configApi = new API({ url: '/account-service/config/order' })
    await this.configApi
      .get()
      .then(response => {
        const orderTypes = response.data.config.order.orderTypes;
        const pickupSupported = orderTypes && orderTypes.includes('PICKUP');
        const deliverySupported = orderTypes && orderTypes.includes('DELIVERY');
        const preOrderSupported = true; // hardcode the value as it is no longer necessary to read from backend
        this.setState({ deliverySupported, pickupSupported, preOrderSupported });
        this.fetchSlotDetails({ deliverySupported, pickupSupported, preOrderSupported });
        const { user } = getSession();
        const isRbStaff = user?.designation?.name === 'Digital Pre-order'; // Digital Pre-order is designation for RB staffs

        (deliverySupported && !isRbStaff) && this.options.push({ text: 'DELIVERY', value: 'DELIVERY' })
        pickupSupported && this.options.push({ text: 'PICKUP', value: 'PICKUP' })
        preOrderSupported && this.options.push({ text: 'RB PREORDER', value: 'RB_PREORDER'})
      })
      .catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        } else {
          this.setState({
            formError: error.message,
          })
        }
      })
  }

  renderTableContent(orderType) {
    return (
      <React.Fragment>
        {this.state.values[orderType].length ? (
          <div className="slot-table-header">
            <div className="slot-name-header">
              {getMessage('slots.heading')}
            </div>
            {this.state.slots[orderType] &&
              this.state.slots[orderType].map((slot, index) => (
                <div className="slot-name" key={`slot-name-${index}`} style={index === 0 ? {paddingTop: '0.75rem'} : {}}>
                  {slot.type === 'STANDARD'
                    ? `${formatTime(slot.startTime)} - ${formatTime(
                        slot.endTime
                      )}`
                    : `${getMessage('slots.asap')} ${getMinutes(
                        slot.endTime
                      )} ${getMessage('slots.asap.minute')}`}
                </div>
              ))}
          </div>
        ) : null}
        <div className="store-slot-rules">
          {this.state.values[orderType].length > 0 ? (
            this.state.values[orderType].map((date, dateIndex) => (
              <div
                className="store-slot-container"
                key={`slotindex-${dateIndex}`}
              >
                <div className="slot-date">
                  {this.state.dates[dateIndex] ===
                  moment().format(dateFormat)
                    ? getMessage(todaySlotText)
                    : this.state.dates[dateIndex] ===
                      moment()
                        .add(1, 'day')
                        .format(dateFormat)
                    ? getMessage(tomorrowSlotText)
                    : moment(this.state.dates[dateIndex]).format(
                        slotDateFormat
                      )}
                </div>
                {this.state.values[orderType][dateIndex] &&
                  this.state.values[orderType][dateIndex].map(
                    (slots, slotIndex) => (
                      <Checkbox
                        key={`slot-checkbox-${orderType}-${dateIndex}-${slotIndex}`}
                        // inlineLabel={getMessage('slots.disable')}
                        name={`status-slot-${orderType}-${dateIndex}-${slotIndex}`}
                        {...this.generateStateMappers({
                          stateKeys: [
                            orderType,
                            dateIndex,
                            slotIndex,
                            'status',
                          ],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    )
                  )}
              </div>
            ))
          ) : (
            <div className="no-slots">
              {getMessage(`slots.${orderType}.empty`)}
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    const defaultSelected = (this.options && this.options.length > 0 && this.options[0].value) || null
    const orderType = this.state.selectedOption || defaultSelected;
    return (
      <div className="slot-store-page">
        {this.state.showSelector && isExtensionEnabled('MultiStoreSupport') && (
          <StoreSelector
            value={
              this.state.storeID || getDefaultStore(this.props.stores).storeId
            }
            onChange={this.changeStore}
            stores={this.props.stores}
          />
        )}
        <h1>{getMessage('slots.store.blocking.title')}</h1>
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form>
            <div className="orderType">
              <Select
                name="orderType"
                className='order-type-select'
                required={true}
                options={this.options}
                placeholder={'OrderType'}
                value={orderType}
                onChange={this.handleOrderSelect}
              />
            </div>
            <div className="table-container">{this.renderTableContent(orderType)}</div>
            {(!this.state.values['DELIVERY'].length && !this.state.values['RB_PREORDER'].length &&
              !this.state.values['PICKUP'].length) ||
              orderType === null ? (
              <EmptyState
                icon={EmptyIcon}
                message={getMessage('slots.blocking.empty.message')}
              />
            ) : null}
            {this.state.values['DELIVERY'].length || this.state.values['RB_PREORDER'].length ||
            this.state.values['PICKUP'].length ? (
              <div className="response-status">
                {this.state.formError && (
                  <div className="form-error">{this.state.formError}</div>
                )}
                {this.state.successMessage && (
                  <Dialog
                    show={this.state.successMessage}
                    close={() => this.setState({ successMessage: null })}
                    title={getMessage('customer.address.success')}
                    information={getMessage('extension.slots.saved')}
                    closeText={getMessage('customer.address.okay')}
                    className="success"
                  />
                )}
                <SubmitButton disabled={this.state.submitting}>
                  {getMessage('deliveryArea.slots.save')}
                </SubmitButton>
                <button
                  type="button"
                  className="button"
                  onClick={this.props.history.goBack}
                >
                  {getMessage('slots.cancel')}
                </button>
              </div>
            ) : null}
          </Form>
        )}
      </div>
    )
  }
}

const InterfaceWithStores = makestoreDependentComponent(InterfaceContainer)

class StoreSlotConfiguration extends Component {
  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <InterfaceWithStores {...this.props} />
      </AuthenticatedPage>
    )
  }
}

export default StoreSlotConfiguration
