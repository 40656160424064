import { Input, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'
import React from 'react'

const CardNameDeeplink = ({ generateStateMappers }) => {
  return (
    <div className="card-input-section">
      <Input
        type="text"
        label={getMessage('app.card.name')}
        placeholder={getMessage('app.card.name.placeholder')}
        {...generateStateMappers({
          stateKeys: ['name'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        required
      />
      <Input
        type="text"
        label={getMessage('app.card.deeplink')}
        placeholder={getMessage('app.card.deeplink.placeholder')}
        {...generateStateMappers({
          stateKeys: ['deepLink'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        required
      />
    </div>
  )
}

export default CardNameDeeplink
