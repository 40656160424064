import {
  orderTypeOptions,
  digitalCheckoutOrderTypes,
  digitalPayViaFpApp,
} from '../../../marketing/Offers/Form/settings'
import { DiscountTypes } from '../../../marketing/Offers/settings'
import ReviewTableOffer from './ReviewTableOffer'

const PROMO_CODE_LABEL = 'Promo Code'

export const isPositiveNumber = (value) => {
  return value !== '' && !isNaN(value) && value >= 0
}

const trim = (value) => {
  return typeof value === 'object' ? value : (value || '').replace(/ /g, '')
}

// Why not just !value? Because 0 isn't empty.
export const isEmpty = (value) =>
  value === null || trim(value) === '' || value === undefined

const validateOrderType = (value) => {
  const orderTypes = (value || '').split(',')
  const orderTypesValues = [
    ...orderTypeOptions,
    ...digitalCheckoutOrderTypes,
    ...digitalPayViaFpApp,
  ].map((item) => item.text?.replace(/ /g, '').toLowerCase())
  if (orderTypes.length > 1) {
    return orderTypes.every((type) =>
      orderTypesValues.includes(type.trim()?.toLowerCase())
    )
  }
  return orderTypesValues.includes(value?.toLowerCase())
}

const isValidSapReference = (sapReference, offerType) => {
  if (sapReference && offerType === 'SFXGCD') {
    return sapReference?.startsWith('ZKP')
  }
  return true
}

const validateTypeOfDiscount = (value, offerType) => {
  if (value && ['BMIN', 'BMINXGFG', 'BMINXATP'].includes(offerType)) {
    return ['combo', 'individual'].includes(value.toLowerCase())
  }
  return true
}

const validateEntityType = (value) => {
  return (
    !!value && ['product', 'category', 'brand'].includes(value.toLowerCase())
  )
}

const validateProduct = (value, offerType, entityType) => {
  if (entityType.toLowerCase() === 'product') {
    return !!value
  }
  return true
}
const validateBrand = (value, offerType, entityType) => {
  if (entityType.toLowerCase() === 'brand') {
    return !!value
  }
  return true
}
const validateCategory = (value, offerType, entityType) => {
  if (entityType.toLowerCase() === 'category') {
    return !!value
  }
  return true
}

const commonConfig = {
  TIMESTAMP: {
    header: 'Timestamp',
    validate: () => true,
    displayHeader: 'Timestamp',
    valueKey: 'timeStamp',
  },
  OFFER_TYPE: {
    header: 'Offer Type',
    validate: (value) => !isEmpty(value),
    displayHeader: 'Offer Type',
    valueKey: 'offerType',
  },
  PAY_VIA_APP_STORES: {
    header: 'Pay via app stores',
    validate: () => true,
    displayHeader: 'Pay via app stores',
    valueKey: 'clientStoreIds',
  },
  DISCOUNT_TYPE: {
    header: 'Discount Type',
    validate: (value) => DiscountTypes.map((item) => item.text).includes(value),
    displayHeader: 'Discount Type',
    valueKey: 'discountType',
  },
  DISCOUNT_VALUE: {
    header: 'Discount Value',
    validate: (value) => Number(value || 0) > 0,
    displayHeader: 'Discount Value',
    valueKey: 'discountValue',
  },
  ORDER_TYPE: {
    header: 'Order Type',
    validate: validateOrderType,
    displayHeader: 'Order Type',
    valueKey: 'orderType',
  },
  VALID_FROM: {
    header: 'Valid From',
    validate: (value) => !isEmpty(value),
    displayHeader: 'Valid From',
    valueKey: 'offerValidFrom',
  },
  VALID_TILL: {
    header: 'Valid Till',
    validate: () => true,
    displayHeader: 'Valid Till',
    valueKey: 'offerValidTill',
  },
  DELIVERY_DATE_VALID_FROM: {
    header: 'Delivery Date Valid from',
    validate: () => true,
    displayHeader: 'Delivery Date Valid from',
    valueKey: 'startDeliveryDate',
  },
  DELIVERY_DATE_VALID_TILL: {
    header: 'Delivery Date Valid till',
    validate: () => true,
    displayHeader: 'Delivery Date Valid till',
    valueKey: 'endDeliveryDate',
  },
  STORES_SELECTION: {
    header: 'Stores selection',
    validate: () => true,
    displayHeader: 'Stores selection',
    valueKey: 'storeId',
  },
  PAYMENT_TYPE: {
    header: 'Payment Type',
    validate: () => true,
    displayHeader: 'Payment Type',
    valueKey: 'paymentType',
  },
  STAKABLE: {
    header: 'Stackable',
    validate: (value) =>
      ['yes', 'no'].indexOf((value || '').toLowerCase()) > -1,
    displayHeader: 'Stackable',
    valueKey: 'stackable',
  },
  HAS_UNIQUE_PROMO: {
    header: 'Has unique Promo Code?',
    validate: (value) =>
      ['yes', 'no'].indexOf((value || '').toLowerCase()) > -1,
    displayHeader: 'Has unique Promo Code?',
    valueKey: 'hasUniquePromocode',
  },
  NUMBER_UNIQUE_PROMO: {
    header: 'Number of unique promo',
    validate: () => true,
    displayHeader: 'Number of unique promo',
    valueKey: 'numberOfPromos',
  },
  UPC_PREFIX: {
    header: 'UPC Pre-fix',
    validate: () => true,
    displayHeader: 'UPC Pre-fix',
    valueKey: 'prefix',
  },
  UPC_SUFFIX: {
    header: 'UPC suffix',
    validate: () => true,
    displayHeader: 'UPC suffix',
    valueKey: 'suffix',
  },
  PROMO_CODE: {
    header: PROMO_CODE_LABEL,
    validate: () => true,
    displayHeader: PROMO_CODE_LABEL,
    valueKey: 'promoCode',
  },
  ADD_TO_WALLET: {
    header: 'Add to wallet',
    validate: (value) =>
      ['yes', 'no', ''].indexOf((value || '').toLowerCase()) > -1,
    displayHeader: 'Add to wallet',
    valueKey: 'addToWallet',
  },
  CUSTOMER_LEVEL_REDEMPTION: {
    header: 'Customer level redemption',
    validate: isPositiveNumber,
    displayHeader: 'Customer level redemption',
    valueKey: 'customerRedemptionLimit',
  },
  TOTAL_REDEMPTION: {
    header: 'Total Redemption',
    validate: isPositiveNumber,
    displayHeader: 'Total Redemption',
    valueKey: 'totalRedemption',
  },
  SEGEMENTS: {
    header: 'Segments',
    validate: () => true,
    displayHeader: 'Segments',
    valueKey: 'segments',
  },
  DESCRIPTION: {
    header: 'Description',
    validate: () => true,
    displayHeader: 'Description',
    valueKey: 'description',
  },
  PROMO_CODE_ICON_TYPE: {
    header: 'Promo Code Icon Type',
    validate: () => true,
    displayHeader: 'Promo Code Icon Type',
    valueKey: 'iconType',
  },
  SAP_REFERENCE: {
    header: 'SAP reference',
    validate: isValidSapReference,
    displayHeader: 'SAP reference',
    valueKey: 'sapReference',
  },
  ELIGIBLE_ITEMS: {
    header: 'Eligible Items',
    validate: () => true,
    displayHeader: 'Eligible Items',
    valueKey: 'eligibleItems',
  },
  WEBSITE_REDIRECT_LINK: {
    header: 'Website re-direct link',
    validate: () => true,
    displayHeader: 'Website re-direct link',
    valueKey: 'webLink',
  },
  MOBILE_REDIRECT_LINK: {
    header: 'Mobile re-direct link',
    validate: () => true,
    displayHeader: 'Mobile re-direct link',
    valueKey: 'mobileLink',
  },
  TITLE: {
    header: 'Title',
    validate: () => true,
    displayHeader: 'Title',
    valueKey: 'title',
  },
  GL_CODE: {
    header: 'GL Code',
    displayHeader: 'GL Code',
    validate: () => false,
    valueKey: 'costCenter',
  },
  SUPPLIER_NAME: {
    header: 'Supplier Name',
    displayHeader: 'Supplier Name',
    validate: () => false,
    valueKey: 'supplierName',
  },
  SUPPLIER_CODE: {
    header: 'Supplier Code',
    displayHeader: 'Supplier Code',
    validate: () => false,
    valueKey: 'supplierCode',
  },
  HOW_TO_USE: {
    header: 'How to use',
    validate: () => true,
    displayHeader: 'How to use',
    valueKey: 'howToUseText',
  },
  TERMS_AND_CONDITIONS: {
    header: 'T&C URL',
    validate: () => true,
    displayHeader: 'T&C URL',
    valueKey: 'tncUrl',
  },
  TREATMENT_ENTRY: {
    header: 'Treatment Entry',
    validate: () => true,
    displayHeader: 'Treatment Entry',
    valueKey: 'treatment',
  },
}

export const cartLevelConfig = {
  name: 'Bulk Offer Upload for Cart Level Offers',
  id: 'bulk-offer-upload-cart',
  sampleCsv: 'sample-bulk-offer-cart',
  isValidFilename: () => true,
  csvHeaders: {
    OFFER_ID: {
      header: 'Offer ID',
      validate: () => false,
      displayHeader: 'Offer ID',
      valueKey: 'offerId',
    },
    EMAIL_ADDRESS: {
      header: 'Email Address',
      validate: () => true,
      displayHeader: 'Email Address',
      valueKey: 'emailAddress',
    },
    CART_PRICE: {
      header: 'Cart Price',
      validate: (value) => !isEmpty(value),
      displayHeader: 'Cart Price',
      valueKey: 'cartPrice',
    },

    SELECT_SLOT: {
      header: 'Select Slot',
      validate: () => true,
      displayHeader: 'Select Slot',
      valueKey: 'slot',
    },
    SUB_TITLE: {
      header: 'Sub Title',
      validate: () => true,
      displayHeader: 'Sub Title',
      valueKey: 'subTitle',
    },
    IMAGE: {
      header: 'Image',
      validate: () => true,
      displayHeader: 'Image',
      valueKey: 'image',
    },
    ORDER_LEVEL_REDEMPTION: {
      header: 'Order level redemption',
      validate: isPositiveNumber,
      displayHeader: 'Order level redemption',
      valueKey: 'orderLevelRedemption',
    },
    ...commonConfig,
  },
  endpoint: '/offer-service/offer',
  ReviewComponent: ReviewTableOffer,
}
export const SKULevelConfig = {
  name: 'Bulk Offer Upload for SKU Level Offers',
  id: 'bulk-offer-upload-sku',
  sampleCsv: 'sample-bulk-offer-sku',
  isValidFilename: () => true,
  csvHeaders: {
    OFFER_ID: {
      header: 'Offer ID',
      validate: () => false,
      displayHeader: 'Offer ID',
      valueKey: 'offerId',
    },
    REFERENCE: {
      header: '(reference)',
      validate: () => true,
      displayHeader: '(reference)',
      valueKey: 'reference',
    },

    EMAIL: {
      header: 'Email',
      validate: () => true,
      displayHeader: 'Email',
      valueKey: 'emailAddress',
    },

    ENTITY_TYPE: {
      header: 'Entity Type',
      validate: validateEntityType,
      displayHeader: 'Entity Type',
      valueKey: 'entityType',
    },
    INCLUDES: {
      header: 'Includes',
      validate: () => true,
      displayHeader: 'Includes',
      valueKey: 'includes',
    },
    ITEM_DISCOUNT_TYPE: {
      header: 'Type of discount',
      validate: validateTypeOfDiscount,
      displayHeader: 'Type of discount',
      valueKey: 'itemDiscountType',
    },
    PRODUCT_SKU: {
      header: 'Product (SKU ID)',
      validate: validateProduct,
      displayHeader: 'Product (SKU ID)',
      valueKey: 'productId',
    },
    CATEGORY_ID: {
      header: 'Category (Category ID)',
      validate: validateCategory,
      displayHeader: 'Category (Category ID)',
      valueKey: 'categoryId',
    },
    BRAND_ID: {
      header: 'Brand (Brand ID)',
      validate: validateBrand,
      displayHeader: 'Brand (Brand ID)',
      valueKey: 'brandId',
    },

    MINIMUM_TYPE: {
      header: 'Minimum type',
      validate: (value) => ['amount', 'quantity'].includes(value),
      displayHeader: 'Minimum type',
      valueKey: 'minimumType',
    },
    MINIMUM_VALUE: {
      header: 'Minimum value',
      validate: (value) => Number(value || 0) > 0,
      displayHeader: 'Minimum value',
      valueKey: 'minimumValue',
    },

    GET_PRODUCT: {
      header: '(Get) Product',
      validate: () => true,
      displayHeader: '(Get) Product',
      valueKey: 'getProduct',
    },
    GET_PRODUCT_QUANTITY: {
      header: '(Get) Product Quantity',
      validate: () => true,
      displayHeader: '(Get) Product Quantity',
      valueKey: 'getProductQuantity',
    },
    SUB_TITLE: {
      header: 'Subtitle',
      validate: () => true,
      displayHeader: 'Subtitle',
      valueKey: 'subTitle',
    },
    IMAGE: {
      header: 'Image URL',
      validate: () => true,
      displayHeader: 'Image URL',
      valueKey: 'imageUrl',
    },
    ...commonConfig,
  },
  endpoint: '/offer-service/offer',
  ReviewComponent: ReviewTableOffer,
}
