import React from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { withRouter } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { LINK_HOST } from 'config/app'
import { Input } from 'components/Form'
import { Popup } from 'components/Popup'
import { prettier } from 'pages/operations/Customers/json-prettifier'

import './styles.scss'

const MergeVc = () => {
  const [primaryVc, setPrimaryVc] = React.useState('')
  const [secondaryVc, setSecondaryVc] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [showPopup, setShowPopup] = React.useState(false)
  const [error, setError] = React.useState('')
  const [result, setResult] = React.useState(null)

  const handleMergeVc = () => {
    setIsLoading(true)
    const mergeVcApi = new API({
      url: `${LINK_HOST}/merge-vc/${primaryVc}/${secondaryVc}`,
    })

    mergeVcApi
      .post()
      .then((res) => {
        setResult(res)
      })
      .catch((err) => {
        setError(err.message)
      })
      .finally(() => {
        setIsLoading(false)
        setShowPopup(true)
      })
  }

  return (
    <div>
      <div>
        <b>{getMessage('customerSupport.linkoperations.mergeVc.title')}</b>
      </div>
      <Input
        type="text"
        label={getMessage(
          'customerSupport.linkoperations.mergePrimaryVc.inputlabel'
        )}
        placeholder={getMessage(
          'customerSupport.linkoperations.mergePrimaryVc.placeholder'
        )}
        name="primaryVc"
        value={primaryVc}
        onChange={(e) => setPrimaryVc(e)}
      />
      <Input
        type="text"
        label={getMessage(
          'customerSupport.linkoperations.mergeSecondaryVc.inputlabel'
        )}
        placeholder={getMessage(
          'customerSupport.linkoperations.mergeSecondaryVc.placeholder'
        )}
        name="secondaryVc"
        value={secondaryVc}
        onChange={(e) => setSecondaryVc(e)}
      />
      <button onClick={handleMergeVc} className="operationsButton">
        {isLoading
          ? '...'
          : getMessage('customerSupport.linkoperations.mergeVc.button')}
      </button>
      <Popup
        show={showPopup}
        heading={error ? 'Error' : 'Merge success'}
        close={() => {
          setShowPopup(false)
          setError('')
          setPrimaryVc('')
          setSecondaryVc('')
          setResult(null)
        }}
      >
        <div>
          {error ? (
            error
          ) : (
            <span className="successCheckmark">Success &#9989;</span>
          )}
        </div>
        <pre>{JSON.stringify(result, null, 4)}</pre>
      </Popup>
    </div>
  )
}

const Patch = (props) => {
  const {
    title,
    label,
    placeholder,
    name,
    buttonText,
    pulaoId,
    refreshLinkAcc,
  } = props
  const [value, setValue] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [showPopup, setShowPopup] = React.useState(false)

  const patchLinkApi = new API({
    url: `${LINK_HOST}/users/${pulaoId}`,
  })

  const handleClickPatch = () => {
    let body = {}
    if (name === 'patchUid') {
      body = { uid: value }
    } else {
      body = { exchangeId: value }
    }
    setIsLoading(true)
    patchLinkApi
      .patch(body)
      .then(() => {
        setIsLoading(false)
        setShowPopup(true)
        refreshLinkAcc()
      })
      .catch((err) => {
        setError(err.message)
        setIsLoading(false)
        setShowPopup(true)
        refreshLinkAcc()
      })
  }

  return (
    <div>
      <div>
        <b>{title}</b>
      </div>
      <Input
        type="text"
        label={label}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => setValue(e)}
      />
      <button
        onClick={handleClickPatch}
        className="operationsButton"
        disabled={value.length === 0}
      >
        {isLoading ? '...' : buttonText}
      </button>
      <Popup
        show={showPopup}
        heading={error ? 'Error' : 'Patch success'}
        close={() => {
          setShowPopup(false)
          setError('')
          setValue('')
        }}
      >
        <div>
          {error ? (
            error
          ) : (
            <span className="successCheckmark">Success &#9989;</span>
          )}
        </div>
      </Popup>
    </div>
  )
}

const LinkOperations = (props) => {
  const virtualCard = props.router.match.params.vc
  const [linkAcc, setLinkAcc] = React.useState([])
  const [error, setError] = React.useState('')

  const getLinkAcc = async () => {
    const linkAccByVcApi = new API({
      url: `${LINK_HOST}/users/search`,
    })

    const apiCall = linkAccByVcApi
      .post({ vc: [virtualCard] })
      .then((res) => res)
      .catch((err) => err)
    const resovleResults = await Promise.resolve(apiCall)

    if (resovleResults.message) {
      setError(resovleResults.message)
      setLinkAcc([])
    } else {
      const elem = document.getElementById('link-acc')
      elem.innerHTML = prettier.prettyPrint(resovleResults[0])
      setLinkAcc(resovleResults)
      setError('')
    }
  }

  const getCardList = async () => {
    const cardApi = new API({
      url: `${LINK_HOST}/cards?vc=${virtualCard}&cardType=physical`,
    })
    cardApi
      .get()
      .then((res) => {
        const elem = document.getElementById('card-list')
        elem.innerHTML = prettier.prettyPrint(res.cards)
        setError('')
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  React.useEffect(() => {
    getLinkAcc()
    getCardList()
  }, [])

  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
    >
      <h1>{getMessage('customerSupport.linkoperations.title')}</h1>
      {error.length === 0 ? (
        <div className="opsParentWrapper">
          <div className="views">
            <div>
              <b>
                {getMessage(
                  'customerSupport.linkoperations.lincAccDisplay.title'
                )}
              </b>
            </div>
            <pre className="link-acc" id="link-acc"></pre>
            <hr />
            <div>
              <b>
                {getMessage(
                  'customerSupport.linkoperations.lincCardDisplay.title'
                )}
              </b>
            </div>
            <pre className="card-list" id="card-list"></pre>
          </div>
          <div className="operations">
            <MergeVc />
            <hr />
            <Patch
              title={getMessage(
                'customerSupport.linkoperations.patchUid.title'
              )}
              label={getMessage(
                'customerSupport.linkoperations.patchUid.inputlabel'
              )}
              placeholder={getMessage(
                'customerSupport.linkoperations.patchUid.placeholder'
              )}
              name={'patchUid'}
              buttonText={getMessage(
                'customerSupport.linkoperations.patchUid.button'
              )}
              pulaoId={linkAcc[0]?.id}
              refreshLinkAcc={() => getLinkAcc()}
            />
            <hr />
            <Patch
              title={getMessage(
                'customerSupport.linkoperations.exchangeId.title'
              )}
              label={getMessage(
                'customerSupport.linkoperations.exchangeId.inputlabel'
              )}
              placeholder={getMessage(
                'customerSupport.linkoperations.exchangeId.placeholder'
              )}
              name={'patchExchangeId'}
              buttonText={getMessage(
                'customerSupport.linkoperations.exchangeId.button'
              )}
              pulaoId={linkAcc[0]?.id}
              refreshLinkAcc={() => getLinkAcc()}
            />
          </div>
        </div>
      ) : (
        <div>Error: unable to fetch</div>
      )}
    </AuthenticatedPage>
  )
}

export default withRouter(({ location, history, match, ...props }) => (
  <LinkOperations router={{ location, history, match }} {...props} />
))
