import React, { useCallback, useEffect, useState } from 'react'
import API from 'lib/api'
import { Text, View, FixedButton } from '../ui'
import { Field, useFormikContext } from 'formik'
import { FormikCustomerInput } from './FormikInput'
import { NewOrderTabs, newOrderPageHeight } from '../constant'
import { ConsentPopup } from './ConsentPopup'

export const CustomerDetailsTab = ({ setActiveIndex }) => {
  const [isLoading, setLoading] = useState(false)

  const { getFieldMeta, values, setFieldValue, setFieldTouched, errors } =
    useFormikContext()

  const [pageError, setPageError] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    /**
     * clear customer form value when this page rendered,
     * to ensure that user wont be stuck with existing errors
     */
    setFieldValue('customerPhone', '')
    setFieldValue('customerName', '')
    setFieldTouched('customerName', false)
    setFieldTouched('customerPhone', false)
  }, [])

  const createUserAndNext = (userInput) => {
    setPageError(false)
    setLoading(true)
    new API({ url: '/ef-customer-core/offline/profile' })
      .post({
        ...userInput,
        country_code: '65',
      })
      .then((res) => {
        const userData = {
          userId: res.data?.id,
          userUid: res.data?.uid,
        }
        setFieldValue('userData', userData)
        // go to next step to select campaign
        setActiveIndex(NewOrderTabs.BROWSE)
      })
      .catch((_) => {
        setPageError(
          'Something went wrong' // default value: 'An error has occured'
        )
        setIsOpenModal(false)
        setLoading(false)
      })
  }

  const onConfirmCustomerDetail = (signature) => {
    // frontend validation error
    const { error } = getFieldMeta()
    if (!error.customerPhone && !error.customerName) {
      // submit customer details
      createUserAndNext({
        name: values.customerName,
        phone_number: values.customerPhone,
        signature,
      })
    }
  }

  const onContinue = useCallback(() => {
    const formNotEmpty = Boolean(
      values.customerName &&
        values.customerName.length > 0 &&
        values.customerPhone &&
        values.customerPhone.length > 0
    )
    if (
      errors.customerName === undefined &&
      errors.customerPhone === undefined &&
      formNotEmpty
    ) {
      setIsOpenModal(true)
    } else {
      setFieldTouched('customerName', true)
      setFieldTouched('customerPhone', true)
    }
  }, [errors, values])

  return (
    <>
      <View
        direction="column"
        justifyContent="space-between"
        style={{ height: `calc(${newOrderPageHeight})` }}
      >
        <View direction="column" alignItems="start">
          <Text variant="h4" margin="0 0 8px" fontWeight={700}>
            Add customer details
          </Text>
          <Field
            component={FormikCustomerInput}
            label="NAME"
            name="customerName"
          />
          <Field
            component={FormikCustomerInput}
            label="MOBILE NUMBER"
            name="customerPhone"
          />
          {pageError && (
            <>
              <Text color="red">Unable to create customer:</Text>
              <Text color="red">{pageError}</Text>
            </>
          )}
        </View>
      </View>
      <FixedButton onClick={onContinue}>Continue</FixedButton>
      {isOpenModal && (
        <ConsentPopup
          error={pageError}
          onConfirm={onConfirmCustomerDetail}
          isOpen={isOpenModal}
          // Prevent user from closing the modal during submission...
          toggleModal={() => (!isLoading ? setIsOpenModal(false) : undefined)}
          isLoading={isLoading}
        />
      )}
    </>
  )
}
