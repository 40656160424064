const data = {
  'customerSupport.search.noCustomer': 'No customer found',
  'customerSupport.search.noOrder': 'No order found',
  'customerSupport.payments.searchText': 'Search',
  'customerSupport.payments.clearAllText': 'ClearAll',
  'customerSupport.payments.paymentStatus.heading': 'PAYMENT STATUS',
  'customerSupport.payments.paymentStatus.label.all': 'All',
  'customerSupport.payments.paymentStatus.label.refunded': 'Refunded',
  'customerSupport.payments.paymentStatus.label.failed': 'Failed',
  'customerSupport.payments.paymentStatus.label.completed': 'Completed',
  'customerSupport.payments.header.paymentRef': 'Payment ref.',
  'customerSupport.payments.header.storeIdName': 'Store ID/name',
  'customerSupport.payments.header.transactionDate': 'Transaction date',
  'customerSupport.payments.header.amount': 'Amount',
  'customerSupport.payments.header.status': 'Status',
  'customerSupport.payments.header.action': 'Action',
  'customerSupport.payments.selectStore.label': 'STORE ID/NAME',
  'customerSupport.payments.selectMerchant.label': 'SERVICE',
  'customerSupport.payments.selectMerchant.placeholder':
    'Select the service provider',
  'customerSupport.payments.empty':
    'No transactions found. Please try with different parameters.',
  'customerSupport.customer.deleteLinkpass.label':
    "This action can't be undone. Please state reason.",
  'customerSupport.customer.deleteLinkpass.placeholder': 'Zendesk ticket ID',
  'order.action.fileDispute': 'File dispute',
  'dispute.submit': 'Submit',
  'calls.action.success.title': 'Success',
  'calls.close': 'Okay',
  'calls.action.success.info': 'Added Successfully',
  'calls.action.form.heading': 'Add Reason & Summary',
  'calls.table.heading.type': 'Type',
  'calls.table.heading.from': 'From',
  'calls.table.heading.to': 'To',
  'calls.table.heading.startTime': 'Start Time',
  'calls.table.heading.reason': 'Reason',
  'calls.table.heading.recording': 'Recording',
  'calls.emptyState.text': 'No Calls Found',
  'calls.form.reason.placeholder': 'Enter Call Reason',
  'calls.form.summary.label': 'Summary',
  'calls.form.summary.placeholder': 'Enter call summary',
  'calls.form.submitText': 'Save',
  'calls.form.cancel': 'Cancel',
  'calls.filters.submitText': 'Submit',
  'calls.filters.clearFiltersText': 'Clear All',
  'calls.filters.phone.label': 'Phone',
  'calls.filters.phone.placeholder': 'Enter Phone number',
  'calls.filters.type.label': 'Type',
  'calls.filters.status.label': 'Status',
  'calls.filters.tag.label': 'Tag',
  'calls.filters.tag.placeholder': 'All',
  'calls.filters.employee.label': 'Employee',
  'calls.filters.employee.placeholder': 'Enter Name',
  'calls.filters.customer.label': 'Customer',
  'calls.filters.customer.placeholder': 'Enter Name',
  'calls.filters.type.label.all': 'All',
  'calls.filters.status.label.all': 'All',
  'calls.filters.type.option.inbound': 'Inbound',
  'calls.filters.type.option.outbound': 'Outbound',
  'calls.filters.status.option.picked': 'Picked',
  'calls.filters.status.option.missed': 'Missed',
  'calls.filters.tag.option.cs': 'CS',
  'calls.filters.tag.option.store': 'Store',
  'calls.filters.tag.option.others': 'Others',
  'customerSupport.egifting.heading': 'E-Gifting Orders',
  'customerSupport.egifting.pendingApproval.heading':
    'E-Gifting Orders - Pending Bank Approval',
  'customerSupport.egifting.header.orderno': 'Order Number',
  'customerSupport.egifting.header.customer': 'Customer Name',
  'customerSupport.egifting.header.email': 'Customer Email Id',
  'customerSupport.egifting.header.submitTime': 'Submit Time',
  'customerSupport.egifting.header.amountPaid': 'Amount Paid',
  'customerSupport.egifting.header.placement-time': 'Placed Time',
  'customerSupport.egifting.header.amount': 'Order Amount',
  'customerSupport.egifting.header.status': 'Order Status',
  'customerSupport.egifting.header.payment-status': 'Payment Status',
  'customerSupport.egifting.header.completedAt': 'Completion Time',
  'customerSupport.egifting.header.actions': 'Actions',
  'customerSupport.egifting.cancel': 'Cancel Order',
  'customerSupport.egifting.empty': 'No Results Found',
  'customerSupport.egifting.resend': 'Resend',
  'customerSupport.egifting.resend.success': 'Resend Successfully!',
  'customerSupport.egifting.details.heading': 'E-Gift Voucher',
  'customerSupport.egifting.details.receiver.name': 'Receiver(s) Name',
  'customerSupport.egifting.details.receiver.resend': 'Resend this voucher?',
  'customerSupport.egifting.resend.error':
    'Selected vouchers have different delivery method and/or different receiver details.',
  'customerSupport.egifting.resend.error_1':
    'Please select 1 method and 1 receiver detail to update all vouchers',
  'customerSupport.egifting.entities': 'Entities',
  'customerSupport.egifting.denomination': 'Denomination',
  'customerSupport.egifting.header.assignment': 'Assignment Code',
  'customerSupport.egifting.header.keyin.assignment': 'Key in Assignment Code',
  'customerSupport.egifting.header.proof.payment': 'View proof of payment',
  'customerSupport.egifting.header.proof.confirm': 'Confirm',
  'customerSupport.egifting.header.proof.reject': 'Reject',
  'customerSupport.egifting.header.payment.submitted': 'Payment submitted on',
  'customerSupport.egifting.header.reject.issuance': 'Reject Issuance',
  'customerSupport.egifting.header.order.reference': 'Order Reference Number',
  'customerSupport.egifting.header.reject.reason': 'Reason for rejecting',
  'customerSupport.egifting.header.reject.remarks': 'Remark(s)',
  'customerSupport.egifting.header.reject.info':
    'Vouchers will not be issued upon confirming Reject Payment action.',
  'customerSupport.egifting.header.save.fail':
    'Failed to save due to server error. Please confirm or reject again.',
  'customerSupport.egifting.header.save.success': 'approved successfully.',
  'customerSupport.egifting.header.save.reject':
    'has been rejected successfully.',
  'customerSupport.egifting.placeholder.reason': 'Please select reason',
  'customerSupport.egifting.bank.transfer': 'Bank Transfer',
  'customerSupport.egifting.approved.by': 'Approved By',
  'customerSupport.egifting.bank': 'Bank',
  'customerSupport.egifting.choose.payment.type': 'Choose payment type',
  'customerSupport.link.title': 'Link support',
  'customerSupport.link.inputlabel': 'Search Link by Virtual card',
  'customerSupport.link.email.inputlabel': 'Search Link by email',
  'customerSupport.link.placeholder': '8109xxxx',
  'customerSupport.link.email.placeholder': 'Enter your email',
  'customerSupport.link.phoneNumber.inputlabel': 'Search Link by phone number',
  'customerSupport.link.phoneNumber.placeholder': 'Enter your phone number',
  'customerSupport.link.searchButton': 'Search',
  'customerSupport.linkoperations.title': 'Link Operations',
  'customerSupport.linkoperations.lincAccDisplay.title': 'Link Acc',
  'customerSupport.linkoperations.mergeVc.title': 'Merge by VC',
  'customerSupport.linkoperations.mergePrimaryVc.inputlabel': 'Primary Vc',
  'customerSupport.linkoperations.mergePrimaryVc.placeholder':
    'Enter Primary Vc',
  'customerSupport.linkoperations.mergeSecondaryVc.inputlabel': 'Secondary Vc',
  'customerSupport.linkoperations.mergeSecondaryVc.placeholder':
    'Enter Secondary Vc',
  'customerSupport.linkoperations.mergeVc.button': 'Merge',
  'customerSupport.linkoperations.lincCardDisplay.title': 'Card List',
  'customerSupport.linkoperations.patchUid.title': 'Patch link account UID',
  'customerSupport.linkoperations.patchUid.inputlabel': 'Patch uid',
  'customerSupport.linkoperations.patchUid.placeholder': 'Enter uid',
  'customerSupport.linkoperations.patchUid.button': 'Patch Uid',
  'customerSupport.linkoperations.exchangeId.title':
    'Patch link account exchange ID',
  'customerSupport.linkoperations.exchangeId.inputlabel': 'Patch exId',
  'customerSupport.linkoperations.exchangeId.placeholder': 'exchange Id',
  'customerSupport.linkoperations.exchangeId.button': 'Patch exchange Id',
  'customerSupport.link.cardNumber.inputlabel': 'Search link by card number',
  'customerSupport.link.cardNumber.placeholder': 'Enter your card number',
}

export default data
