import { useEffect } from 'react'

export const useLockScroll = (query, callback) => {
  useEffect(() => {
    const element = document.querySelector(query)
    element.classList.add('lockScroll')
    callback()
    return () => {
      const cleanupElement = document.querySelector(query)
      cleanupElement.classList.remove('lockScroll')
    }
  }, [])
}
