import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#2b3238'
const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
    },
    no: {
      width: '5%',
      textAlign: 'center',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    sku: {
      width: '15%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'center',
    },
    productDescription: {
      width: '60%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'left',
      paddingLeft: 8,
    },
    qty: {
      width: '10%',
      textAlign: 'center',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    uom: {
      width: '10%',
      textAlign: 'center',
  },
  });


const OrderItemsTableRow = ({items, isDropoffTable}) => (
  <>
    {
      items.map((item, index) =>
        <View style={styles.row} key={item.item.id} wrap={false}>
          <Text style={styles.no}>{index+1}</Text>
          <Text style={styles.sku}>{item.item.clientItemId}</Text>
          <Text style={styles.productDescription}>{item.item.name} {item.item.displayUnit}</Text>
          <Text style={styles.qty}>
            {
              isDropoffTable ?
              item.orderedQuantity - item.deliveredQuantity
                : item.deliveredQuantity
            }
          </Text>
          <Text style={styles.uom}>{item.item.unitOfMeasurement}</Text>
        </View>
    )}
  </>
);

  export default OrderItemsTableRow
