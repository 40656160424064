import React from 'react'
import ManageTilesForm from './Form'
import { Link } from 'react-router-dom'
import { tableProperties } from 'components/AppHome/AppTile/utils'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import './style.css'

const linkButtons = () => {
  return (
    <div className="header-wrap">
      <Link to="/app-home/tiles-dressup/add">
        <button className="primary button">
          <span className="text">Create Tiles</span>
        </button>
      </Link>
    </div>
  )
}

const ManageTiles = (props) => {
  return (
    <ListingPageWithRoutes
      testid="apptile-listing"
      title="Manage Tiles"
      addHeading="Create Tiles"
      editHeading="Edit Tiles"
      menu={props.menu}
      className="apptile-listingpage"
      api={{
        url: `/genie/lms/tiles_v5`,
        transform: (res) => res.items,
      }}
      headerActions={linkButtons}
      emptyState={{ actions: linkButtons }}
      form={{
        component: ManageTilesForm,
      }}
      tableProperties={tableProperties()}
      viewHeading="Tile Dress-up Details"
      modalClassName="resend-popup"
      tableDynamic
    />
  )
}

export default ManageTiles
