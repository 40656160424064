import React from 'react'
import { Input, Select } from '../../Form'
import { getMessage } from '../../../lib/translator'

const OfferFields = ({
  _this,
  taskIndex,
  awardIndex,
  offerDetails,
  offerID,
  tagIds,
  offerError,
  handleSearch,
  type,
}) => (
  <>
    <div className="col-6">
      <Input
        name={`select-offerid-${awardIndex}`}
        label={getMessage('omnichallenge.task.offerID')}
        placeholder={getMessage('omnichallenge.task.offerID')}
        type="text"
        value={offerID}
        {..._this.generateStateMappers({
          stateKeys: [
            'taskList',
            taskIndex,
            'awardList',
            awardIndex,
            'offerID',
          ],
        })}
        secondaryAction={{
          name: (
            <img
              key="search"
              src="/static/media/icon-search.462fc200.svg"
              alt={`select-offerid-${awardIndex}`}
            />
          ),
          onAction: (e) => {
            handleSearch(e, awardIndex, offerID)
          },
        }}
      />
      {offerError && <div className="error">{offerError}</div>}
      {offerDetails && (
        <div className="offer-select-value">
          <small>{offerDetails}</small>
        </div>
      )}
    </div>
    {type === 'OFFER' && (
      <div className="col-6">
        <Select
          name="tag-id"
          label={getMessage('omnichallenge.task.customerTag')}
          placeholder={getMessage('omnichallenge.task.customerTag')}
          options={tagIds}
          {..._this.generateStateMappers({
            stateKeys: [
              'taskList',
              taskIndex,
              'awardList',
              awardIndex,
              'tagId',
            ],
          })}
        />
      </div>
    )}
  </>
)

export default OfferFields
