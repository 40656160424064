import React, { useReducer } from 'react'
import { withRouter } from 'react-router-dom'

import AuthenticatedPage from 'containers/AuthenticatedPage'
import { Input } from 'components/Form'
import API from 'lib/api'
import { LINK_HOST } from 'config/app'
import { getMessage } from 'lib/translator'

import './styles.scss'

const getSearchApiBody = ({ vc, email, phone, cardNumber }) => {
  if (vc) {
    return { vc: [vc] }
  }
  if (email) {
    return { email: [email] }
  }
  if (phone) {
    return { phoneNumber: [phone] }
  }
  if (cardNumber) {
    return { cardNumber: [cardNumber] }
  }
  return {}
}

const defaultState = {
  type: '',
  value: { vc: '', email: '', phone: '', card: '' },
}

// reducer for the different types on input
// stores the value and type
const reducer = (state, action) => {
  if (action.value.length === 0) {
    return defaultState
  }
  switch (action.type) {
    case 'vc':
      return { type: 'vc', value: { ...defaultState.value, vc: action.value } }
    case 'email':
      return {
        type: 'email',
        value: { ...defaultState.value, email: action.value },
      }
    case 'phone':
      return {
        type: 'phone',
        value: { ...defaultState.value, phone: action.value },
      }
    case 'card':
      return {
        type: 'card',
        value: { ...defaultState.value, card: action.value },
      }
    default:
      return defaultState
  }
}

const Link = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const [searchResults, setSearchResults] = React.useState([])
  const [error, setError] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const searchLinkByVc = async () => {
    setIsLoading(true)
    const linkAccByVcApi = new API({
      url: `${LINK_HOST}/users/search`,
    })

    const apiCall = linkAccByVcApi
      .post(
        getSearchApiBody({
          vc: state.value.vc,
          email: state.value.email,
          phone: state.value.phone,
          cardNumber: state.value.card,
        })
      )
      .then((res) => {
        setIsLoading(false)
        return res
      })
      .catch((err) => {
        setIsLoading(false)
        return err
      })
    const resovleResults = await Promise.resolve(apiCall)

    if (resovleResults.message) {
      setError(resovleResults.message)
      setSearchResults([])
    } else {
      setSearchResults(resovleResults.length ? resovleResults : [])
      setError('')
    }
  }

  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
    >
      <div className="searchLinkByVc">
        <h1>{getMessage('customerSupport.link.title')}</h1>
        <div className="inputWrappers">
          <Input
            type="text"
            label={getMessage('customerSupport.link.inputlabel')}
            placeholder={getMessage('customerSupport.link.placeholder')}
            name="searchLinkAccByVc"
            value={state.value.vc}
            onChange={(e) => dispatch({ type: 'vc', value: e })}
            disabled={state.type !== 'vc' && state.type.length > 0}
          />
          <Input
            type="text"
            label={getMessage('customerSupport.link.email.inputlabel')}
            placeholder={getMessage('customerSupport.link.email.placeholder')}
            name="searchLinkAccByEmail"
            value={state.value.email}
            onChange={(e) => dispatch({ type: 'email', value: e })}
            disabled={state.type !== 'email' && state.type.length > 0}
          />
          <Input
            type="text"
            label={getMessage('customerSupport.link.phoneNumber.inputlabel')}
            placeholder={getMessage(
              'customerSupport.link.phoneNumber.placeholder'
            )}
            name="searchLinkAccByPhone"
            value={state.value.phone}
            onChange={(e) => dispatch({ type: 'phone', value: e })}
            disabled={state.type !== 'phone' && state.type.length > 0}
          />
          <Input
            type="text"
            label={getMessage('customerSupport.link.cardNumber.inputlabel')}
            placeholder={getMessage(
              'customerSupport.link.cardNumber.placeholder'
            )}
            name="searchLinkAccByCard"
            value={state.value.card}
            onChange={(e) => dispatch({ type: 'card', value: e })}
            disabled={state.type !== 'card' && state.type.length > 0}
          />
        </div>
        <button onClick={searchLinkByVc}>
          {isLoading ? '...' : getMessage('customerSupport.link.searchButton')}
        </button>
      </div>
      <div className="searchLinkResult">
        {searchResults.length > 0 && (
          <div className="linkAccHeading">
            <div>Vc</div>
            <div>Name</div>
            <div>Phone</div>
            <div>Email</div>
          </div>
        )}
        {error.length > 0 && (
          <div>
            <div>
              <b>Error</b>
            </div>
            <div>{error}</div>
          </div>
        )}
        {searchResults.length > 0 ? (
          searchResults.map((res) => {
            return (
              <a key={res.vc} href={`/customer-support/link/vc/${res.vc}`}>
                <div className="linkAcc">
                  <div className="accItem">{res.vc}</div>
                  <div className="accItem">
                    {res.firstName} {res.lastName}
                  </div>
                  <div className="accItem">{res.primaryPhoneNumber}</div>
                  <div className="accItem">{res.email}</div>
                </div>
              </a>
            )
          })
        ) : (
          <div>No link acc</div>
        )}
      </div>
    </AuthenticatedPage>
  )
}

export default withRouter(({ location, history, match, ...props }) => (
  <Link router={{ location, history, match }} {...props} />
))
